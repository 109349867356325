import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import CTAMask from '@images/cta_mask.svg';
import Wrapper from '@components/wrapper.styled';
import { generateScales } from '@helpers/generate-media-queries';
import { Button } from '@styles/button.styled';
import { Link } from 'gatsby';

export const StyledWrapper = styled(Wrapper)`
  position: relative;
  z-index: 3;
`;

export const StyledSection = styled.section<{ backgroundColor: keyof typeof defaultTheme.colors }>`
  ${({ theme, backgroundColor }) => css`
    width: 100%;
    position: relative;
    background-color: ${theme.colors[backgroundColor]};
    ${generateScales('padding-top', '3.5rem', '8rem')}
    ${generateScales('padding-bottom', '2.5rem', '8rem')}
    overflow: hidden;
  `}
`;

export const PlacerMask = styled.img`
  ${({ theme }) => css`
    height: auto;
    width: calc(${theme.wrappers.xxl} - (${theme.spacing.wrapperGap} * 2));
    opacity: 0;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      width: calc(${theme.wrappers.xl} - (${theme.spacing.wrapperGap} * 2));
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: calc(${theme.wrappers.lg} - (${theme.spacing.wrapperGap} * 2));
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: auto;
      height: 100%;
    }
  `}
`;

export const StyledImage = styled.img`
  ${({ theme }) => css`
    height: auto;
    width: calc(${theme.wrappers.xxl} - (${theme.spacing.wrapperGap} * 2));
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 1;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      width: calc(${theme.wrappers.xl} - (${theme.spacing.wrapperGap} * 2));
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: calc(${theme.wrappers.lg} - (${theme.spacing.wrapperGap} * 2));
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: auto;
      height: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const StyledMask = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: calc(50% - (${theme.wrappers.xxl} / 2) + ${theme.spacing.wrapperGap});
    bottom: 0;
    z-index: 2;
    width: calc(${theme.wrappers.xxl} - (${theme.spacing.wrapperGap} * 2));
    mask-image: url(${CTAMask});
    mask-size: 100% 100%;
    mask-repeat: no-repeat;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      left: calc(50% - (${theme.wrappers.xl} / 2) + ${theme.spacing.wrapperGap});
      width: calc(${theme.wrappers.xl} - (${theme.spacing.wrapperGap} * 2));
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: calc(50% - (${theme.wrappers.lg} / 2) + ${theme.spacing.wrapperGap});
      width: calc(${theme.wrappers.lg} - (${theme.spacing.wrapperGap} * 2));
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: fit-content;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const StyledBackgroundImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const Content = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: 50%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 50%;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
      text-align: center;
    }
  `}
`;

export const StyledColorLink = styled(Link)<{
  $center?: boolean;
  $primaryColor?: string;
  $hoverColor?: string;
}>`
  ${Button}
  ${generateScales('margin-top', '1rem', '2rem')}

  ${({ theme, $center, $primaryColor, $hoverColor }) => css`
    ${$primaryColor ? `background-color: ${$primaryColor as string};` : ''}
    ${$hoverColor
      ? `
    &:hover {
      background-color: ${$hoverColor as string};
    }`
      : ''}

    ${$center ? 'margin-left: auto;' : ''}
    ${$center ? 'margin-right: auto;' : ''} 
    font-weight: ${theme.fontWeights.bold};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 100%;
      max-width: 300px;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      margin-left: auto;
      margin-right: auto;
    }
  `}
`;
