import styled from 'styled-components';
import Text from '@components/text';

export const Container = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 80%;
    
    @media (max-width: ${theme.breakpoints.xxl}) {
      max-width: 85%;
    }

    @media (max-width: ${theme.breakpoints.xl}) {
      max-width: 90%;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      max-width: 100%;
    }
  `}
`;

export const Title = styled(Text)`
  /* margin-bottom: 1rem; */
`;

export const BlockContainer = styled.div`
  ${({ theme }) => `
    margin-top: 4rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4rem;

    @media (max-width: ${theme.breakpoints.xxl}) {
      grid-gap: 3.5rem;
    }

    @media (max-width: ${theme.breakpoints.xl}) {
      grid-gap: 3rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      grid-gap: 2.5rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      grid-gap: 2rem;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
    }
  `}
`;
export const Block = styled.div`
  margin: 0;
`;

export const BlockDescription = styled(Text)`
  margin: 0;
`;

export const BlockTitle = styled(Text)`
  margin-bottom: 0rem;

  + ${BlockDescription} {
    display: block;
    margin-top: 1rem;
  }
`;
