import React, { useRef, useState, useEffect, useMemo, useContext, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { RichTextField } from '@prismicio/types';
import Text, { TextHead } from '@components/text';
import Wrapper from '@components/wrapper.styled';
import Slider from 'react-slick';
import Section from '@components/section';
import generateId from '@helpers/generate-id';
import { defaultTheme } from '@styles/theme.styled';
import SubtleSlideFade from '@components/animations/SubtleSlideFade';
import Terminal from '../terminal';
import {
  SlickContainer,
  SliderNav,
  SliderNavList,
  Indicator,
  SliderNavItem,
  SlideLabel,
  StyledSlideSelect,
  CodeSection,
  CodeWrapper,
  StyledVideo,
  StyledTab,
} from './code-carousel.styled';

const carouselIdGenerator = generateId();

export interface CodeCarouselItemProps {
  slide_caption?: string;
  code: {
    richText: RichTextField;
  };
  terminal: {
    richText: RichTextField;
  };
  response: {
    richText: RichTextField;
  };
  ui_demo: {
    url: string;
  };
  ui_demo_poster: {
    url: string;
  };
}

interface CodeCarouselProps {
  eyebrow?: string;
  title?: string;
  items?: CodeCarouselItemProps[];
}

export const CarouselOptionContext = React.createContext({ autoplayState: false, slideIndex: 0 });

const SlideSelect: React.FC<
  PropsWithChildren<{
    index: number;
    slideTo: (i: number) => void;
  }>
> = ({ children, index, slideTo }): JSX.Element => {
  const { autoplayState, slideIndex } = useContext(CarouselOptionContext);
  return (
    <StyledSlideSelect
      $isAutoPlaying={autoplayState}
      $isActive={slideIndex === index}
      role="button"
      onClick={() => slideTo(index)}
    >
      {children}
    </StyledSlideSelect>
  );
};

const CodeCarousel = ({ eyebrow, title, items = [] }: CodeCarouselProps): JSX.Element => {
  const sliderRef = useRef<Slider | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [userProp, setUserProp] = useState<string | null>('Concept');
  const [autoplayState, setAutoplayState] = useState<boolean>(false);
  const [dimensions, setDimensions] = useState({
    windowHeight: 0,
    windowWidth: 0,
  });
  const [activeTab, setActiveTab] = useState<'ui' | 'code'>('ui');
  const [loadVideo, setLoadVideo] = useState(false);

  const slideTo = (index: number) => {
    const sliderElement = sliderRef.current;
    if (sliderElement) {
      sliderElement.slickGoTo(index);
    }
  };

  // play & set autoplay state to true if on desktop resolutions
  const handleAutoplay = () => {
    const sliderElement = sliderRef.current;
    const isDesktopWidth = dimensions.windowWidth >= parseInt(defaultTheme.breakpoints.md, 10);
    if (isDesktopWidth && sliderElement) {
      sliderElement.slickPlay();
      setAutoplayState(true);
    }
  };

  // pause and set autoplay state to false
  const pauseSlider = () => {
    const sliderElement = sliderRef.current;

    if (sliderElement) {
      sliderElement.slickPause();
      setAutoplayState(false);
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    autoplay: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    autoplaySpeed: 12000,
    infinite: true,
    speed: 250,
    swipe: false,
    swipeToSlide: false,
    beforeChange: (_: number, index: number) => {
      if (inputRef.current !== null) {
        inputRef.current.value = '';
      }
      setUserProp('Concept');
      setSlideIndex(index);
    },
    responsive: [
      {
        breakpoint: parseInt(defaultTheme.breakpoints.md, 10),
        settings: {
          swipe: true,
          swipeToSlide: true,
        },
      },
    ],
  };

  // responsive handler for autoplay state
  useEffect(() => {
    setLoadVideo(true);

    // set window dimensions & autoplay state
    const handler = () => {
      setDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      });
      handleAutoplay();
    };

    // initiate handle and attach to resize event if on client / window available
    if (typeof window !== `undefined`) {
      handler();
      window.addEventListener(`resize`, handler);
    }

    // remove listener
    return () => window.removeEventListener(`resize`, handler);
  }, []);

  // handle default state for dynamic variable
  useEffect(() => {
    if (userProp === '') {
      setUserProp('Concept');
    }
  }, [userProp]);

  const sliderNav = useMemo(() => {
    return (
      <SliderNav>
        <SliderNavList>
          <SubtleSlideFade cascade direction="top" duration={200} triggerOnce>
            {items.map(({ slide_caption }, index) => {
              return (
                <SliderNavItem key={carouselIdGenerator.next().value}>
                  <SlideSelect index={index} slideTo={slideTo}>
                    <Indicator />
                    <SlideLabel>{slide_caption}</SlideLabel>
                  </SlideSelect>
                </SliderNavItem>
              );
            })}
          </SubtleSlideFade>
        </SliderNavList>
      </SliderNav>
    );
  }, [items]);

  const carouselOptionContextState = useMemo(() => {
    return { autoplayState, slideIndex };
  }, [autoplayState, slideIndex]);

  return items?.length > 0 ? (
    <Section backgroundColor="orinoco">
      <Wrapper>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
        </Helmet>
        <SubtleSlideFade direction="left" triggerOnce>
          <TextHead align="left">
            {!!eyebrow && <Text type="eyebrow" stringText={eyebrow} />}
            {!!title && <Text type="title" stringText={title} />}
          </TextHead>
        </SubtleSlideFade>
        <CodeWrapper onMouseEnter={pauseSlider} onMouseLeave={handleAutoplay}>
          <CarouselOptionContext.Provider value={carouselOptionContextState}>
            {sliderNav}
          </CarouselOptionContext.Provider>
          <CodeSection>
            <StyledTab
              onClick={() => {
                setActiveTab('ui');
                setAutoplayState(false);
              }}
              active={activeTab === 'ui'}
            >
              UI
            </StyledTab>
            <StyledTab
              onClick={() => {
                setActiveTab('code');
                setAutoplayState(false);
              }}
              active={activeTab === 'code'}
            >
              SDK
            </StyledTab>
            <Slider ref={sliderRef} {...settings}>
              {items?.map(({ code, response, ui_demo, terminal, ui_demo_poster }) => {
                return (
                  <SlickContainer key={carouselIdGenerator.next().value}>
                    {activeTab === 'ui' && loadVideo && (
                      <StyledVideo autoPlay loop poster={ui_demo_poster?.url} playsInline>
                        <source src={ui_demo?.url} type="video/mp4" />
                      </StyledVideo>
                    )}
                    {activeTab === 'code' && (
                      <Terminal
                        code={code?.richText}
                        terminal={terminal?.richText}
                        response={response?.richText}
                      />
                    )}
                  </SlickContainer>
                );
              })}
            </Slider>
          </CodeSection>
        </CodeWrapper>
      </Wrapper>
    </Section>
  ) : (
    <></>
  );
};

export default CodeCarousel;
