import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => `
    margin-bottom: 8rem;

    @media (max-width: ${theme.breakpoints.xl}) {
      margin-bottom: 6rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      margin-bottom: 5rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      margin-bottom: 4rem;
    }
  `}
`;
