import React from 'react';
import {
  ChromeFrame,
  ChromeControls,
  CloseControl,
  MinimizeControl,
  MaximizeControl,
  ChromeBody,
  LinesWrapper,
  Line,
  Code,
  Syntax,
} from './terminal.styled';

const Terminal = (): JSX.Element => {
  const Lines: JSX.Element[] = [];
  for (let i = 0; i < 16; i += 1) {
    Lines.push(<Line key={`line-${i}`}>{i + 1}</Line>);
  }

  return (
    <ChromeFrame>
      <ChromeControls>
        <CloseControl />
        <MinimizeControl />
        <MaximizeControl />
      </ChromeControls>
      <ChromeBody>
        <LinesWrapper>{Lines}</LinesWrapper>
        <Code>
          <div>
            <Syntax textColor="muted">from</Syntax> composer{' '}
            <Syntax textColor="muted">import</Syntax> trainer, algorithms
          </div>
          <div>
            trainer_hparams <Syntax textColor="muted">=</Syntax> trainer.load(
            <Syntax textColor="accent">&quot;resnet50&quot;</Syntax>)
          </div>
          <div>
            &nbsp;&nbsp;<Syntax textColor="muted">method</Syntax>.backdrop(),
            <br /> &nbsp;&nbsp;
            <Syntax textColor="muted">method</Syntax>.blurpool()
          </div>
          <div>
            &nbsp;&nbsp;<Syntax textColor="accent">&quot;squeeze_excite&quot;</Syntax>,
          </div>
          <div>
            &nbsp;&nbsp;<Syntax textColor="accent">&quot;scale_schedule&quot;</Syntax>])
          </div>
          ])
          <div>
            trainer <Syntax textColor="muted">=</Syntax> trainer_hparams.initialize_object()
          </div>
          <div>trainer.fit()</div>
        </Code>
      </ChromeBody>
    </ChromeFrame>
  );
};

export default Terminal;
