import styled from 'styled-components';
import { Link } from 'gatsby';
import Arrow from '@components/svg-icons/arrow';
import Chevron from '@components/svg-icons/chevron';
import { generateScales } from '@helpers/generate-media-queries';
import { StyledLink } from '@components/header/header.styled';

export const DropdownContainer = styled.div`
  width: fit-content;
  position: relative;

  ${({ theme }) => `
  @media only screen and (min-width: ${theme.breakpoints.md}) {
    &:hover svg {
      transform: rotate(270deg);
    }
  }
  `}
`;

export const StyledArrow = styled(Arrow)`
  margin-left: 0.5rem;
  transform: rotate(90deg);
  height: auto;
  width: 1rem;
`;

export const StyledChevronContainer = styled.div`
  margin-left: 10px;
`;

export const StyledChevron = styled(Chevron)<{ hideOnMobile?: boolean }>`
  ${({ theme, hideOnMobile }) => `
  transform: rotate(90deg);
  transition: all 0.3s ease-in;
  height: 0.7rem;
  width: 0.6rem;

  ${
    hideOnMobile
      ? `
  @media only screen and (max-width: ${theme.breakpoints.md}) {
    display: none;
  }`
      : ''
  }
`}
`;

const mainAnimationTime = 0.25;

export const DropdownStyledLink = styled(StyledLink)`
  ${({ theme }) => `
  @media only screen and (max-width: ${theme.breakpoints.md}) {
    padding-bottom: 0;
  }
  `}
`;

export const StyledDropDown = styled.ul<{ isVisible?: boolean }>`
  ${({ theme, isVisible }) => `
    padding: 0.5rem 0.5rem;
    width: 100%;
    min-width: 8rem;
    max-width: 12rem;
    position: absolute;
    top: calc(100% - 1rem);
    left: 0;
    margin: 0;
    opacity: 0;
    font-family: ${theme.fontFamily.display};
    z-index: 5;
    ${generateScales('font-size', '0.8375rem', '1rem')}
    box-shadow: ${theme.shadows.small};
    border-radius: ${theme.borderRadius.small};
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
    transition: top ${mainAnimationTime}s, visibility 0.01s ${mainAnimationTime}s,
    opacity ${mainAnimationTime}s;

    ${
      !isVisible
        ? `
    pointer-events: none;
    `
        : ``
    };

    ${
      isVisible
        ? `
    display: block;
    top: calc(100% - 0.5rem);
    transition: top ${mainAnimationTime}s 0.01s, visibility 0.01s, opacity ${mainAnimationTime}s 0.01s;
    visibility: visible;
    opacity: 1;
    `
        : ``
    };

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      box-shadow: none;
      position: relative;
      transition: none;
      visibility: visible;
      opacity: 1;
      pointer-events: all;
    }
  `}
`;

export const Button = styled.button<{ isVisible?: boolean }>`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.sm;

    return `
    text-decoration: none;
    font-family: ${theme.fontFamily.display};
    font-size: ${theme.fontSize.body.desktop};
    cursor: pointer;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    border-width: ${theme.borderWidths.ui};
    border-style: solid;
    border-color: ${theme.colors.mindaro};
    background-color: ${theme.colors.bottleGreen};
    color: ${theme.colors.mindaro};

    ${generateScales('font-size', '0.8375rem', '1rem')}
    ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
    ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
    ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
    ${generateScales('height', mobile.height, desktop.height)}
    `;
  }}

  ${({ isVisible }) => `
    ${
      isVisible
        ? `
        ${StyledArrow} {
          transform: rotate(-90deg);
        }
    `
        : ``
    };
  `}
`;

export const Text = styled.span`
  ${({ theme }) => `
    width: 100%;
    color: ${theme.colors.black};
    font-family: ${theme.fontFamily.display};
    ${generateScales('font-size', '0.8375rem', '1rem')}
    line-height: ${theme.lineHeights.sm};
    text-decoration: none;
    border: none;
  `}
`;

export const Links = styled(Link)`
  display: flex;
  padding: 0.5rem 0.5rem;
  text-decoration: none;

  ${({ theme }) => `
    border-radius: calc(${theme.borderRadius.small} / 2);

    @media only screen and (min-width: ${theme.breakpoints.md}) {
      :hover {
        ${Text} {
          color: ${theme.colors.emerald};
        }
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding: 1rem;
    }
  `}
`;
