import styled from 'styled-components';
import { LargeButton } from '@styles/button.styled';

export default styled.a`
  ${LargeButton}

  ${({ theme }) => {
    const spacing = theme.spacing.md;

    return `

    margin-top: ${spacing};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      margin-top: calc(${spacing} * ${theme.scales.lg});
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin-top: calc(${spacing} * ${theme.scales.md});
    }
  `;
  }}
`;
