import React from 'react';
import { RichTextField } from '@prismicio/types';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';
import { Container, StyledTitle } from '@components/rich-text/rich-text.styled';
import { RichTextContainer } from '@components/paragraphs/paragraphs.shared.styled';

interface RichTextProps {
  title?: string;
  description?: RichTextField;
}

const RichText = ({ title, description }: RichTextProps): JSX.Element => {
  return (
    <Section backgroundColor="white">
      <Wrapper>
        <Container>
          <StyledTitle type="title" textSize="heading4" stringText={title} />
          <RichTextContainer>
            <Text richText={description} />
          </RichTextContainer>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default RichText;
