import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { RichTextField } from '@prismicio/types';
import Image, { ImageProps } from '@components/image';
import { NoImage } from '@components/posts-list/posts-list.shared.styled';
import generateId from '@helpers/generate-id';
import getContrastYIQ from '@helpers/get-color-contrast';
import { defaultTheme } from '@styles/theme.styled';
import {
  Card,
  Meta,
  ImageContainer,
  Title,
  Subtitle,
  Description,
  SocialLink,
  StyledImage,
  SocialLinkContainer,
} from './bio-card.styled';

const bioIdGenerator = generateId();

export interface BioCardProps {
  photo?: ImageProps;
  name?: {
    text?: string;
  };
  job_title?: {
    text?: string;
  };
  description?: {
    richText: RichTextField;
  };
  social_links: {
    image?: {
      alt: string;
      url: string;
    };
    link_label?: string;
    link_url?: {
      url: string;
      target: string;
    };
  }[];
  itemsPerRow?: number;
  action?: () => void;
  isOpen?: boolean;
}

export const getBioPath = (BioName = ''): string => BioName.split(' ').join('-').toLowerCase();

const BioCard = ({
  photo,
  name,
  job_title,
  description,
  social_links = [],
  itemsPerRow = 3,
  action,
  isOpen = false,
}: BioCardProps): JSX.Element => {
  if (!name?.text) {
    return <></>;
  }

  const textColor = getContrastYIQ(defaultTheme.colors.bottleGreen);

  return (
    <Card itemsPerRow={itemsPerRow} onClick={action} isOpen={isOpen}>
      <ImageContainer isOpen={isOpen}>
        {photo?.gatsbyImageData || photo?.url ? (
          <Image
            gatsbyImageData={photo?.gatsbyImageData}
            url={photo?.url}
            alt={`Photo of ${name.text}`}
          />
        ) : (
          <NoImage />
        )}
      </ImageContainer>
      <Meta>
        <Title>{name.text}</Title>
        {!!job_title?.text && <Subtitle>{job_title.text}</Subtitle>}
        {isOpen && (
          <Description color={textColor}>
            {description?.richText.length ? (
              <PrismicRichText field={description.richText} />
            ) : (
              'No description'
            )}
          </Description>
        )}
        {isOpen && social_links.length > 0 && (
          <SocialLinkContainer>
            {social_links.map(({ image, link_label, link_url }) => {
              return (
                <SocialLink
                  key={bioIdGenerator.next().value}
                  title={link_label}
                  href={link_url?.url}
                >
                  <StyledImage src={image?.url} alt={link_label} />
                </SocialLink>
              );
            })}
          </SocialLinkContainer>
        )}
      </Meta>
    </Card>
  );
};

export default BioCard;
