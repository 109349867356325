import React from 'react';
import { RichTextField } from '@prismicio/types';
import Wrapper from '@components/wrapper.styled';
import Text, { TextHead } from '@components/text';
import Terminal from '@components/terminal';
import Subheading from '@components/text/subheading';
import SubtleSlideFade from '@components/animations/SubtleSlideFade';
import { defaultTheme } from '@styles/theme.styled';
import { ContainerStyled, StyledSection, TerminalContainerStyled } from './code-demo.styled';

export interface CodeDemoProps {
  eyebrow: string | undefined;
  title: string | undefined;
  description:
    | {
        richText: RichTextField;
      }
    | undefined;
  code:
    | {
        richText: RichTextField;
      }
    | undefined;
  terminal:
    | {
        richText: RichTextField;
      }
    | undefined;
  response:
    | {
        richText: RichTextField;
      }
    | undefined;
  backgroundColor: keyof typeof defaultTheme.colors | undefined;
}

const CodeDemo = ({
  eyebrow,
  title,
  description,
  code,
  terminal,
  response,
  backgroundColor,
}: CodeDemoProps): JSX.Element | null => {
  return (
    <StyledSection backgroundColor={backgroundColor ?? 'white'}>
      <Wrapper>
        <Text type="eyebrow" stringText={eyebrow} textSize="eyebrow" textColor="white" />
        <ContainerStyled>
          <TerminalContainerStyled>
            <Terminal
              code={code?.richText}
              terminal={terminal?.richText}
              response={response?.richText}
            />
          </TerminalContainerStyled>
          <div>
            <SubtleSlideFade direction="right" triggerOnce>
              <TextHead align="left">
                {!!title && <Text type="title" stringText={title} />}
                {description ? <Subheading richText={description?.richText} /> : <></>}
              </TextHead>
            </SubtleSlideFade>
          </div>
        </ContainerStyled>
      </Wrapper>
    </StyledSection>
  );
};

export default CodeDemo;
