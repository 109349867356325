export interface LinkType {
  url?: string;
  uid?: string;
  type?: string;
}

const getUrl = (link: LinkType | undefined): string => {
  if (link) {
    return link.uid ? `/${link.uid}` : link.url || '';
  }
  return '';
};

export const getLink = (url?: string, document?: LinkType): string | undefined => {
  const uid = document?.uid;
  if (uid) {
    if (uid === 'home') {
      return '/';
    }
    if (document.type === 'post') {
      return `/blog/${uid}`;
    }
    return `/${uid}`;
  }
  return url;
};

export default getUrl;
