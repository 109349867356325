import styled from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import StyledInput from '../input.styled';

const SmallInput = styled(StyledInput)`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.sm;
    return `
      width: 12rem;
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('height', mobile.height, desktop.height)}
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
    `;
  }}
`;

export default SmallInput;
