import { RichTextField } from '@prismicio/types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';

function convertTextToChildArray(text: RichTextField | undefined): JSX.Element[] {
  return (
    (text &&
      text
        .map((t) => ('text' in t ? t.text : '').split('').map((c) => <span>{c}</span>))
        .reduce((acc: JSX.Element[], curr: JSX.Element[]) => [...acc, ...curr, <br />], [])) ?? [
      <></>,
    ]
  );
}

const StyledCascadeFade = styled(Fade)`
  display: inline;
  & > span {
    white-space: pre;
  }
`;

function TypingAnimation({
  triggerOnce,
  text,
  done,
}: {
  text?: RichTextField;
  triggerOnce?: boolean;
  done?: () => void;
}): JSX.Element {
  const delay = 1e3;
  const duration = 0.01;
  const damping = 2000;
  const textChars = useMemo(() => convertTextToChildArray(text), [text]);
  const timeout = useRef<number | undefined>();

  useEffect(() => {
    clearTimeout(timeout.current);
  }, [textChars]);

  const onChange = useCallback(
    (inView: boolean) => {
      if (inView && !timeout.current) {
        timeout.current = setTimeout(
          () => done?.(),
          delay + duration * damping * textChars.length
        ) as unknown as number;
      }
    },
    [textChars]
  );

  return (
    <StyledCascadeFade
      delay={delay}
      duration={duration}
      cascade
      damping={damping}
      triggerOnce={triggerOnce}
      onVisibilityChange={onChange}
      style={{ opacity: 0 }}
    >
      {textChars}
    </StyledCascadeFade>
  );
}

export default TypingAnimation;
