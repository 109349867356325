import styled, { css } from 'styled-components';
import Image from '@components/image';

export const Container = styled.div`
  width: 100vw;
  margin-bottom: 2rem;
  .slick-track {
    display: flex;
    .slick-slide {
      height: auto;
      div,
      img {
        height: 100%;
      }
    }
  }
  .slick-list {
    overflow: hidden;
    width: 100%;
  }
`;

export const SlickContainer = styled.div`
  margin: 0 1rem;
`;

export const SlickImage = styled(Image)`
  ${({ theme }) => css`
    height: 28rem !important;
    width: auto;
    border-radius: ${theme.borderRadius.small};
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: 20rem;
    }
  `}
`;

export const Dots = styled.ul`
  ${({ theme }) => `
  &.slick-dots {
    bottom: -2.5rem;
    li {
      margin: 0 1rem 0 0;
      width: 1rem;
      height: 1rem;
      button {
        width: 1rem;
        height: 1rem;
        &:before {
          font-size: 1rem;
          line-height: 1rem;
          width: 1rem;
          height: 1rem;
          color: ${theme.colors.emerald};
          opacity: 1;
        }
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        width: 0.5rem;
        height: 0.5rem;
        button {
          width: 0.5rem;
          height: 0.5rem;
          &:before {
            font-size: 0.5rem;
            line-height: 0.5rem;
            width: 0.5rem;
            height: 0.5rem;
          }
        }
      }
    }
  }
  &.slick-dots li.slick-active button:before {
    color: ${theme.colors.bottleGreen};
    opacity: 1;
  }
  `}
`;
