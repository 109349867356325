import styled from 'styled-components';

export const TooltipContainer = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.text};
    position: relative;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1;
  `}
`;

export const TooltipBox = styled.div<{
  isVisible?: boolean;
}>`
  ${({ theme, isVisible }) => `
    white-space: nowrap;
    position: absolute;
    background: ${theme.colors.orinoco};
    border: 1px solid ${theme.colors.orinoco};
    color: ${theme.colors.black};
    padding: 0.5rem 0.5rem;
    border-radius: 0.3125rem;
    bottom: calc(100% + 0.5rem);
    left: 50%;
    transform: translate(-50%);
    display: ${isVisible ? 'block' : 'none'};
    z-index: 1;
  `}
`;

export const TooltipArrow = styled.span`
  ${({ theme }) => `
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    border-width: 0.3125rem;
    border-style: solid;
    border-color: transparent transparent ${theme.colors.orinoco} transparent;
  `}
`;
