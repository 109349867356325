import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';

function getAnimationForDirection(
  direction: 'left' | 'right' | 'bottom' | 'top',
  verySubtle?: boolean
) {
  const distance = verySubtle ? 10 : 50;
  const xMultiplier = direction === 'left' ? -1 : 1;
  const yMultiplier = direction === 'bottom' ? -1 : 1;
  const xDistance = direction === 'left' || direction === 'right' ? distance : 0;
  const yDistance = direction === 'top' || direction === 'bottom' ? distance : 0;
  return keyframes`
  from {
    opacity: 0;
    transform: translate3d(${xDistance * xMultiplier}px, ${yDistance * yMultiplier}px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
}

function SubtleSlideFade({
  direction,
  cascade,
  duration,
  triggerOnce,
  children,
  verySubtle,
  fraction,
}: {
  triggerOnce?: boolean;
  direction: 'left' | 'right' | 'bottom' | 'top';
  cascade?: boolean;
  duration?: number;
  children: JSX.Element | JSX.Element[];
  verySubtle?: boolean;
  fraction?: number;
}) {
  return (
    <Reveal
      keyframes={getAnimationForDirection(direction, verySubtle)}
      cascade={cascade}
      duration={duration}
      triggerOnce={triggerOnce}
      damping={0.1}
      fraction={fraction}
      style={{ opacity: 0 }}
    >
      {children}
    </Reveal>
  );
}

export default SubtleSlideFade;
