import styled from 'styled-components';
import Grid from '@components/grid';
import Text from '@components/text';

export const OverviewGrid = styled(Grid)`
  grid-gap: 3rem;
`;

export const Title = styled(Text)`
  font-size: 1.75rem;
  line-height: 2rem;
`;

export const Description = styled(Text)`
  margin-top: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.875rem;
`;
