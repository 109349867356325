import styled, { css } from 'styled-components';
import PillBackground from '@images/pill_background.svg';
import Section from '@components/section';

export const StyledSection = styled(Section)`
  overflow: hidden;
`;

export const ContainerStyled = styled.div`
  ${({ theme }) => css`
    column-gap: 2rem;
    display: flex;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -40%;
      right: -30%;
      z-index: -1;
      background: url(${PillBackground}) 0 0 no-repeat;
      transform: rotate(-30deg);
      opacity: 20%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column-reverse;
      &::before {
        display: none;
      }
    }
  `}
`;

export const TerminalContainerStyled = styled.div`
  ${({ theme }) => css`
    width: 50%;
    flex-grow: 1;
    flex-shrink: 0;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 100%;
      margin-right: 0rem;
      margin-top: 2rem;
    }
  `}
`;
