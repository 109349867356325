import { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';

export const Card = css<{
  size?: 'sm' | 'md' | 'lg';
  displayShadow?: boolean;
  color?: keyof typeof defaultTheme.colors;
}>`
  // Shadow
  ${({ theme, displayShadow = true }) =>
    displayShadow === true &&
    css`
      box-shadow: ${theme.shadows.normal};
    `}

  // Color
  ${({ theme, color }) =>
    color &&
    css`
      background: ${theme.colors[color]};
    `}
`;

export const SmallCard = css`
  ${Card}

  ${({ theme }) => `
    padding: 2rem 1rem;
    border-radius: ${theme.borderRadius.small};
  `}
`;

export const MediumCard = css`
  ${Card}

  ${({ theme }) => {
    const paddingY = '4rem';
    const paddingX = '3rem';

    return `
      padding: ${paddingY} ${paddingX};
      border-radius: ${theme.borderRadius.normal};

      // responsive padding

      @media only screen and (max-width: ${theme.breakpoints.xl}) {
        padding: calc(${paddingY} * 0.75) calc(${paddingX} * 0.75);
      }
      
      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        padding: calc(${paddingY} * 0.375) calc(${paddingX} * 0.25);
      }
    `;
  }}
`;

export const LargeCard = css`
  ${Card}

  ${({ theme }) => `
    padding: 4rem;
    border-radius: ${theme.borderRadius.normal};

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      width: 75%;
      padding: ${theme.padding.md} ${theme.padding.md};
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: 81.25%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 87.5%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
      padding: ${theme.padding.md} ${theme.padding.sm};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
      padding: ${theme.padding.md} ${theme.padding.xs};
    }
  `}
`;
