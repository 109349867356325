import styled, { css } from 'styled-components';
import { SmallButton } from '@styles/button.styled';

export const CookieConsentWrapper = styled.div`
  ${({ theme }) => css`
    .cookie-consent-container {
      line-height: ${theme.lineHeights.lg};
      padding: 1.5rem calc((100vw - (${theme.wrappers.xxl})) / 2);
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 2rem;
      position: fixed;
      width: 100%;
      color: ${theme.colors.white};
      z-index: 999;
      font-family: ${theme.fontFamily.display};
      font-size: ${theme.fontSize.small.desktop};
      font-weight: 500;
      background: ${theme.colors.bottleGreen};
      box-shadow: -0.25rem -0.5rem 2rem rgba(0, 0, 0, 0.2);

      @media only screen and (max-width: ${theme.breakpoints.xxl}) {
        padding: 1.5rem calc((100vw - (${theme.wrappers.xl})) / 2);
      }

      @media only screen and (max-width: ${theme.breakpoints.xl}) {
        padding: 1.5rem calc((100vw - (${theme.wrappers.lg})) / 2);
      }

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        padding: 1.5rem calc((100vw - (${theme.wrappers.md})) / 2);
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        padding: 1.5rem calc((100vw - (${theme.wrappers.sm})) / 2);
        flex-direction: column;
        justify-items: flex-start;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        padding: 1.5rem calc((100vw - (${theme.wrappers.xs})) / 2);
      }
    }

    .cookie-consent-btn-wrapper {
      display: flex;
      column-gap: 0.5rem;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        width: 100%;
        padding: 0 ${theme.spacing.wrapperGap};
        margin-top: 1.5rem;
      }
    }

    .cookie-consent-content {
      max-width: 48rem;
      padding: 0 ${theme.spacing.wrapperGap};
    }

    .cookie-consent-decline-btn {
      background-color: transparent;
      color: ${theme.colors.mindaro};
    }
  `}
`;

export const StyledButton = styled.button`
  ${SmallButton}
`;
