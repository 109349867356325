import React, { useState, ReactNode } from 'react';
import { TooltipContainer, TooltipBox, TooltipArrow } from './tooltip.styled';

export interface ButtonProps {
  children: ReactNode;
  text: string;
  handleMouseLeave?: () => void;
}

const Tooltip = ({ handleMouseLeave, children, text }: ButtonProps): JSX.Element => {
  const [show, setShow] = useState(false);

  const onMouseEnter = () => {
    setShow(true);
  };

  const onMouseLeave = () => {
    setShow(false);

    if (handleMouseLeave) {
      handleMouseLeave();
    }
  };

  return (
    <TooltipContainer>
      <TooltipBox isVisible={show}>
        {text}
        <TooltipArrow />
      </TooltipBox>
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {children}
      </div>
    </TooltipContainer>
  );
};

export default Tooltip;
