import React from 'react';
import { RichTextField } from '@prismicio/types';
import { TextHead } from '@components/text';
import { defaultTheme } from '@styles/theme.styled';
import { ImageProps } from '@components/image';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import getContrastYIQ from '@helpers/get-color-contrast';
import {
  Eyebrow,
  StyledImage,
  ImageBorder,
  Block,
  Container,
  ImageContainer,
  StyledGrid,
  StyledText,
  Description,
  Title,
} from './board-members.styled';

const boardMembersIdGenerator = generateId();
const textColor = getContrastYIQ(defaultTheme.colors.bottleGreen);

export interface BoardMembersItem {
  photo?: ImageProps;
  title?: {
    text?: string;
  };
  description?: {
    richText: RichTextField;
  };
}

export interface BoardMembersProps {
  eyebrow?: string;
  title?: string;
  items?: BoardMembersItem[];
  itemsPerRow?: number;
}

const BoardMembers = ({
  eyebrow,
  title,
  items = [],
  itemsPerRow = 4,
}: BoardMembersProps): JSX.Element => {
  return (
    <Section backgroundColor="bottleGreen">
      <Wrapper widthSize="md">
        <Container>
          <TextHead>
            {eyebrow !== '' && <Eyebrow type="eyebrow" stringText={eyebrow} />}
            <StyledText
              tag="h3"
              textColor={textColor}
              textSize="heading3"
              type="title"
              stringText={title}
            />
          </TextHead>
          {items.length > 0 && (
            <StyledGrid>
              {items?.map((item) => {
                const firstRtNode = item.description?.richText?.[0];
                const hasDescription: boolean =
                  item.description?.richText.length !== undefined &&
                  item.description?.richText.length > 0 &&
                  Boolean(firstRtNode && 'text' in firstRtNode && firstRtNode.text !== '');

                if (item.photo?.url === null && item.title?.text === '') {
                  return null;
                }
                return (
                  <Block itemsPerRow={itemsPerRow} key={boardMembersIdGenerator.next().value}>
                    <ImageContainer>
                      <ImageBorder>
                        <StyledImage
                          gatsbyImageData={item.photo?.gatsbyImageData}
                          url={item.photo?.url}
                          alt={item.photo?.alt}
                        />
                      </ImageBorder>
                    </ImageContainer>
                    <Title
                      type="title"
                      textSize="body"
                      textColor="white"
                      stringText={item.title?.text}
                    />
                    {hasDescription && <Description richText={item.description?.richText} />}
                  </Block>
                );
              })}
            </StyledGrid>
          )}
        </Container>
      </Wrapper>
    </Section>
  );
};

export default BoardMembers;
