import React, { useState } from 'react';
import Tooltip from '@components/tooltip';
import copyOnClick from '@helpers/copy-on-ckick';
import { CommandFrame, StyledSmallButton, StyledCode } from './command-line.styled';
import { Syntax } from '../terminal/terminal.styled';

const CommandLine = (): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false);
  const commandText = 'pip install mosaic';
  const tooltipText = isCopied ? 'Copied!' : `Copy command`;
  const handleButtonMouseLeave = () => {
    setIsCopied(false);
  };

  return (
    <CommandFrame>
      <StyledCode>
        <div>
          <Syntax textColor="tertiary">$</Syntax> <Syntax textColor="accent">{commandText}</Syntax>
        </div>
        <Tooltip text={tooltipText} handleMouseLeave={handleButtonMouseLeave}>
          <StyledSmallButton
            variant="primary"
            onClick={() => copyOnClick(commandText, () => setIsCopied(true))}
          >
            Copy
          </StyledSmallButton>
        </Tooltip>
      </StyledCode>
    </CommandFrame>
  );
};

export default CommandLine;
