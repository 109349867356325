import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '@layouts/default-layout';
import SEO from '@components/seo';
import renderModule from '@components/module';
import generateId from '@helpers/generate-id';
import { ImageProps } from '@components/image';
import {
  UnknownRecord,
  useMergePrismicPreviewData,
  UsePrismicPreviewDataResult,
  withPrismicPreview,
} from 'gatsby-plugin-prismic-previews';
import { RichTextField } from '@prismicio/types';

const indexPageIdGenerator = generateId();

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface MainPageInterface extends UnknownRecord {
  prismicGeneralConfig: {
    data: {
      page_name?: string;
      favicon?: {
        url?: string;
      };
      logo?: ImageProps;
      banner_rich_text: {
        richText: RichTextField;
      };
    };
  };
  allPrismicPage: {
    nodes: {
      data: {
        page_title?: {
          text: string;
        };
        meta_description?: string;
        meta_title?: string;
        meta_image?: {
          url?: string;
        };
        body: [];
      };
    }[];
  };
}

const IndexPage: React.FC = () => {
  const staticQueryResult: MainPageInterface = useStaticQuery(graphql`
    query MainPageQuery {
      prismicGeneralConfig {
        ...GeneralConfigFragment
      }
      allPrismicPage(filter: { uid: { eq: "home" } }) {
        nodes {
          ...PageFragment
        }
      }
    }
  `);

  const {
    data: {
      allPrismicPage,
      prismicGeneralConfig: {
        data: { favicon, banner_rich_text },
      },
    },
  }: UsePrismicPreviewDataResult<MainPageInterface> =
    useMergePrismicPreviewData<MainPageInterface>(staticQueryResult);

  const hideBanner = Boolean(
    !banner_rich_text?.richText?.length ||
      (banner_rich_text?.richText?.length === 1 &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (banner_rich_text?.richText[0] as any)?.text === '')
  );
  return (
    <Layout showBanner={!hideBanner}>
      <SEO
        meta_image={allPrismicPage?.nodes[0].data.meta_image?.url}
        meta_description={allPrismicPage?.nodes[0].data.meta_description}
        meta_title={allPrismicPage?.nodes[0].data.meta_title}
        title={allPrismicPage?.nodes[0].data.page_title?.text || 'Home'}
        faviconHref={favicon?.url || ''}
      />
      <Container>
        {allPrismicPage.nodes[0].data.body.map(
          (module) =>
            module && (
              <React.Fragment key={indexPageIdGenerator.next().value}>
                {renderModule(module)}
              </React.Fragment>
            )
        )}
      </Container>
    </Layout>
  );
};

export default withPrismicPreview(IndexPage);
