/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import SubtleSlideFade from '@components/animations/SubtleSlideFade';
import Section from '@components/section';
import Text, { TextHead } from '@components/text';
import Subheading from '@components/text/subheading';
import { StyledVideo } from '@components/video-feature/video-feature.styled';
import Wrapper from '@components/wrapper.styled';
import getContrastYIQ from '@helpers/get-color-contrast';
import { RichTextField } from '@prismicio/types';
import { defaultTheme } from '@styles/theme.styled';
import React, { useEffect, useState } from 'react';

export interface VideoFeatureProps {
  eyebrow: string | undefined;
  title: string | undefined;
  description:
    | {
        richText: RichTextField;
      }
    | undefined;
  video: { url: string } | undefined;
  videoPoster: { url: string } | undefined;
  backgroundColor: keyof typeof defaultTheme.colors;
}

const VideoFeature = ({
  eyebrow,
  title,
  description,
  video,
  videoPoster,
  backgroundColor,
}: VideoFeatureProps): JSX.Element => {
  const textColor = getContrastYIQ(defaultTheme.colors[backgroundColor]);
  const [loadVideo, setLoadVideo] = useState(false);

  useEffect(() => {
    setLoadVideo(true);
  }, []);

  return (
    <>
      <Section backgroundColor={backgroundColor}>
        <Wrapper>
          <>
            <TextHead align="left">
              <Text type="eyebrow" stringText={eyebrow} textSize="eyebrow" textColor={textColor} />
              <SubtleSlideFade direction="left" triggerOnce>
                {title ? <Text type="title" stringText={title} textColor={textColor} /> : <></>}
                {description ? (
                  <Subheading richText={description?.richText} textColor={textColor} />
                ) : (
                  <></>
                )}
              </SubtleSlideFade>
            </TextHead>
            {loadVideo && (
              <StyledVideo autoPlay muted loop playsInline preload="none" poster={videoPoster?.url}>
                <source src={video?.url} type="video/mp4" />
                Your browser does not support the video tag.
              </StyledVideo>
            )}
          </>
        </Wrapper>
      </Section>
    </>
  );
};

export default VideoFeature;
