import React, { useEffect, useRef, useState } from 'react';
import TwitterIcon from '@components/svg-icons/twitter';
import LinkedInIcon from '@components/svg-icons/linkedin';
import RedditIcon from '@components/svg-icons/reddit';
import ShareIcon from '@components/svg-icons/share';
import Tooltip from '@components/tooltip';
import copyOnClick from '@helpers/copy-on-ckick';
import { Container, Title, Media, MediaIcon, MediaShareIcon } from './share-post.styled';

interface SharePostProps {
  url: string;
  title: string;
  description: string;
}

function escapeChars(str: string) {
  return encodeURIComponent(str);
}

const SharePost = ({ url, title, description }: SharePostProps): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false);
  const shareRefs = useRef<{ [key: string]: HTMLAnchorElement | null }>({});

  const tooltipText = isCopied ? 'Copied!' : `Copy URL`;

  const handleButtonMouseLeave = () => {
    setIsCopied(false);
  };

  const media = [
    {
      name: 'twitter',
      link: `https://twitter.com/intent/tweet?url=${escapeChars(url)}&text=${escapeChars(title)}`,
      icon: <TwitterIcon />,
    },
    {
      name: 'linkedin',
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${escapeChars(
        url
      )}&title=${escapeChars(title)}&summary=${escapeChars(description)}`,
      icon: <LinkedInIcon />,
    },
    {
      name: 'reddit',
      link: `https://www.reddit.com/submit?url=${escapeChars(url)}&title=${escapeChars(title)}`,
      icon: <RedditIcon />,
    },
    {
      name: 'share',
      link: url,
      icon: <ShareIcon />,
    },
  ];

  // We need to do this for some reason - maybe when we bump the React version this weird bug will be fixed?
  useEffect(() => {
    Object.entries(shareRefs.current).forEach(([key, value]) => {
      const link = media.find((m) => m.name === key)?.link;
      if (link) {
        value?.setAttribute('href', link);
      }
    });
  }, [url, title, description]);

  return (
    <Container>
      <Title>Share</Title>
      <Media>
        {media.map(({ icon, name, link }) => {
          return name !== 'share' ? (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              key={name}
              ref={(el) => {
                shareRefs.current[name] = el;
              }}
            >
              <MediaIcon>{icon}</MediaIcon>
            </a>
          ) : (
            <Tooltip text={tooltipText} handleMouseLeave={handleButtonMouseLeave} key={name}>
              <MediaShareIcon
                role="button"
                onClick={() => copyOnClick(url, () => setIsCopied(true))}
              >
                {icon}
              </MediaShareIcon>
            </Tooltip>
          );
        })}
      </Media>
    </Container>
  );
};

export default SharePost;
