import React from 'react';
import { defaultTheme } from '@styles/theme.styled';
import BGImage from '@images/cta_background.svg';
import CTAMask from '@images/cta_mask.svg';
import getUrl from '@helpers/get-url';
import { ImageProps } from '@components/image';
import Text from '@components/text';
import { Fade } from 'react-awesome-reveal';
import {
  StyledMask,
  StyledBackgroundImage,
  StyledWrapper,
  StyledSection,
  StyledImage,
  Container,
  Content,
  StyledColorLink,
  PlacerMask,
} from './call-to-action.styled';

interface CallToActionProps {
  title?: string;
  link?: {
    uid?: string;
    url?: string;
  };
  linkLabel?: string;
  backgroundColor?: keyof typeof defaultTheme.colors;
  backgroundImage?: ImageProps;
}

const CallToAction = ({
  title,
  link,
  linkLabel = 'Request a Demo',
  backgroundColor = 'white',
  backgroundImage,
}: CallToActionProps): JSX.Element => {
  const url = getUrl(link);
  if (!title && !url) {
    return <></>;
  }

  return (
    <StyledSection backgroundColor={backgroundColor}>
      <StyledImage src={BGImage} />
      <StyledMask>
        <PlacerMask src={CTAMask} />
        <StyledBackgroundImage src={backgroundImage?.url} />
      </StyledMask>
      <StyledWrapper>
        <Container>
          <Content>
            {!!title && (
              <Fade triggerOnce>
                <Text
                  textColor="white"
                  type="title"
                  stringText={title}
                  tag="h2"
                  textSize="heading3"
                />
              </Fade>
            )}
            {!!url && (
              <StyledColorLink title="Contact us" to={url} $hoverColor="white">
                {linkLabel}
              </StyledColorLink>
            )}
          </Content>
        </Container>
      </StyledWrapper>
    </StyledSection>
  );
};

export default CallToAction;
