import React from 'react';
import { ImageProps } from '@components/image';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import Text, { TextHead } from '@components/text';
import { defaultTheme } from '@styles/theme.styled';
import generateId from '@helpers/generate-id';
import getContrastYIQ from '@helpers/get-color-contrast';
import useScrollToSection from '@hooks/use-scroll-to-section';
import { RichTextField } from '@prismicio/types';
import SubtleSlideFade from '@components/animations/SubtleSlideFade';
import {
  Container,
  Eyebrow,
  WrapContainer,
  StyledLink,
  StyledImage,
  StyledInfoContainer,
  StyledPartnerName,
} from './investors.styled';

const investorsIdGenerator = generateId();
const textColor = getContrastYIQ(defaultTheme.colors.white);

export interface InvestorsPropsItem {
  image?: ImageProps;
  link?: {
    url: string;
  };
  firm?: string;
  names?: string;
  row?: string;
}

export interface InvestorsProps {
  id?: string | undefined;
  eyebrow?: string;
  title?: string;
  items?: InvestorsPropsItem[];
  description?: {
    richText: RichTextField;
  };
}

const Investor = ({ investor }: { investor: InvestorsPropsItem }) => {
  const { link, image, names, firm } = investor;
  return (
    <StyledLink
      href={link?.url}
      rel="noreferrer"
      target="_blank"
      key={investorsIdGenerator.next().value}
    >
      <StyledImage gatsbyImageData={image?.gatsbyImageData} url={image?.url} alt={image?.alt} />
      <StyledInfoContainer>
        {names?.split(',').map((name) => (
          <StyledPartnerName stringText={name} textColor={textColor} />
        ))}
        <Text stringText={firm} textColor={textColor} />
      </StyledInfoContainer>
    </StyledLink>
  );
};

const InvestorRow = ({ investorRows }: { investorRows: InvestorsPropsItem[][] }) => {
  return (
    <>
      {investorRows.map((investorRow) => (
        <WrapContainer>
          <SubtleSlideFade direction="bottom" cascade triggerOnce>
            {investorRow?.map((investor) => {
              if (investor.image?.url === null && investor.link?.url === '') {
                return <></>;
              }

              return <Investor investor={investor} />;
            })}
          </SubtleSlideFade>
        </WrapContainer>
      ))}
    </>
  );
};

const Investors = ({
  id,
  eyebrow,
  title,
  description,
  items = [],
}: InvestorsProps): JSX.Element => {
  useScrollToSection(id);
  const areItemsProvided: boolean =
    items?.length > 0 &&
    (items[0].image?.gatsbyImageData !== null || items[0].image?.url !== null) &&
    items[0].link?.url !== null;

  if (!title || !areItemsProvided) {
    return <></>;
  }

  const investorDict = items.reduce(
    (acc: { [key: string]: InvestorsPropsItem[] }, curr: InvestorsPropsItem) => {
      const key = curr.row ?? '';
      if (key in acc) {
        acc[key].push(curr);
      } else {
        acc[key] = [curr];
      }
      return acc;
    },
    {}
  );

  const rows = Array.from(Object.entries(investorDict))
    .sort((i) => {
      const row = i[0];
      const int = parseInt(row ?? '', 10);
      return Number.isNaN(int) ? Number.MAX_SAFE_INTEGER : int;
    })
    .map((i) => i[1]);

  return (
    <Section backgroundColor="white" id={id}>
      <Wrapper alignment="center">
        <Container>
          <TextHead>
            <Eyebrow type="eyebrow" stringText={eyebrow} />
            <Text
              tag="h3"
              textColor={textColor}
              textSize="heading3"
              type="title"
              stringText={title}
            />
            {description && (
              <Text type="subheading" richText={description.richText} textColor={textColor} />
            )}
          </TextHead>
          {areItemsProvided && <InvestorRow investorRows={rows} />}
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Investors;
