import React from 'react';

interface LinkedInIconProps {
  fill?: string;
}

const LinkedInIcon = ({ fill }: LinkedInIconProps): JSX.Element => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 4.75C12.1935 4.75 13.3381 5.22411 14.182 6.06802C15.0259 6.91193 15.5 8.05653 15.5 9.25V14.5H12.5V9.25C12.5 8.85218 12.342 8.47064 12.0607 8.18934C11.7794 7.90804 11.3978 7.75 11 7.75C10.6022 7.75 10.2206 7.90804 9.93934 8.18934C9.65804 8.47064 9.5 8.85218 9.5 9.25V14.5H6.5V9.25C6.5 8.05653 6.97411 6.91193 7.81802 6.06802C8.66193 5.22411 9.80653 4.75 11 4.75Z"
      fill="white"
    />
    <path d="M3.5 5.5H0.5V14.5H3.5V5.5Z" fill="white" />
    <path
      d="M2 3.25C2.82843 3.25 3.5 2.57843 3.5 1.75C3.5 0.921573 2.82843 0.25 2 0.25C1.17157 0.25 0.5 0.921573 0.5 1.75C0.5 2.57843 1.17157 3.25 2 3.25Z"
      fill={fill}
    />
  </svg>
);

export default LinkedInIcon;
