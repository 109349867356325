import React, { useEffect, useState } from 'react';
import Text, { TextHead } from '@components/text';
import Section from '@components/section';
import { defaultTheme } from '@styles/theme.styled';
import useScrollToSection from '@hooks/use-scroll-to-section';
import { GreenhouseEmbed, StyledWrapper } from './greenhouse-job-board.styled';

interface GreenhouseJobBoardProps {
  id?: string;
  eyebrow?: string;
  title?: string;
  token?: string;
  backgroundColor?: keyof typeof defaultTheme.colors;
  full_page: boolean;
}

const GreenhouseJobBoard = ({
  id,
  eyebrow,
  title,
  full_page = false,
  token = 'coactivesystems',
  backgroundColor = 'white',
}: GreenhouseJobBoardProps): JSX.Element => {
  useScrollToSection(id);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = `https://boards.greenhouse.io/embed/job_board/js?for=${token}`;
    scriptTag.async = true;
    scriptTag.addEventListener('load', () => setLoaded(true));
    document.body.appendChild(scriptTag);

    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  useEffect(() => {
    if (!loaded) return;
    // Greenhouse script initializes after window load which may occur before script loads
    if (Grnhse !== undefined) {
      Grnhse.Iframe.autoLoad();
    }
  }, [loaded]);

  return (
    <Section backgroundColor={backgroundColor} id={id}>
      <StyledWrapper widthSize="lg" alignment="center">
        <TextHead>
          {!!eyebrow && <Text type="eyebrow" stringText={eyebrow} />}
          {!!title && (
            <Text textSize="heading4" type="title" stringText={title} textColor="bottleGreen" />
          )}
        </TextHead>
        <GreenhouseEmbed id="grnhse_app" $isFullpage={full_page} />
      </StyledWrapper>
    </Section>
  );
};

export default GreenhouseJobBoard;
