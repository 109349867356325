import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { BackgroundDiagonal } from '@styles/background-diagonal.styled';
import { generateScales } from '@helpers/generate-media-queries';

const createCSS = () => {
  let styles = '';
  Object.keys(defaultTheme.colors).forEach((color) => {
    styles += `
      &.section-${color} + .section-${color} {
        padding-top: 0;
      }
    `;
  });

  return css`
    ${styles}
  `;
};

const createDiagonalCSS = () => {
  let styles = '';
  Object.keys(defaultTheme.colors).forEach((color) => {
    styles += `
      &.section-${color} + .section-${color} {
        padding-top: 0;

        ::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 100%;
          height: 8rem;
          width: 100%;
          background-color: ${defaultTheme.colors[color as keyof typeof defaultTheme.colors]};
        }
      }
    `;
  });
  return css`
    ${styles}
  `;
};

export const StyledSection = styled.section<{
  backgroundColor: keyof typeof defaultTheme.colors;
  isDiagonal?: boolean;
  backgroundImageUrl?: string;
  backgroundPosition: string;
}>`
  width: 100%;
  position: relative;
  ${generateScales('padding-top', '3.5rem', '8rem')}
  ${generateScales('padding-bottom', '2.5rem', '8rem')}

  ${({
    theme,
    backgroundColor = 'transparent',
    isDiagonal,
    backgroundImageUrl,
    backgroundPosition,
  }) =>
    backgroundColor
      ? css`
          ${isDiagonal === true
            ? css`
                ${BackgroundDiagonal}

                + .section {
                  margin-top: 0;
                  padding-top: 0;
                }

                ${createDiagonalCSS()}

                :last-of-type {
                  ::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 8rem;
                    width: 100%;
                    background-color: ${defaultTheme.colors[backgroundColor]};

                    @media (max-width: ${theme.breakpoints.xl}) {
                      height: 6rem;
                    }

                    @media (max-width: ${theme.breakpoints.lg}) {
                      height: 5rem;
                    }

                    @media (max-width: ${theme.breakpoints.md}) {
                      height: 4rem;
                    }
                  }
                }
              `
            : css`
                background: ${theme.colors[backgroundColor]} url(${backgroundImageUrl}) no-repeat
                  ${backgroundPosition} center;
                ${createCSS()}
              `}
        `
      : `
        background-color: transparent;
      `}
`;

export const Content = styled.div`
  ${({ theme }) => `
    z-index: ${theme.zIndex.layeredContent};
    position: relative;
  `}
`;
