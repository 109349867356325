import React from 'react';
import { RichTextField } from '@prismicio/types';
import * as prismicH from '@prismicio/helpers';

import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import { Container } from '@components/rich-text/rich-text.styled';
import { LegalTextContainer } from '@components/paragraphs/paragraphs.shared.styled';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export interface MarkdownProps {
  markdown?: RichTextField;
}

const Markdown = ({ markdown }: MarkdownProps): JSX.Element => {
  if (!markdown) {
    return <></>;
  }
  const source = prismicH.asText(markdown);
  return (
    <Section backgroundColor="white">
      <Wrapper>
        <Container>
          <LegalTextContainer>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{source}</ReactMarkdown>
          </LegalTextContainer>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Markdown;
