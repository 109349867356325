import styled from 'styled-components';
import Text from '@components/text';
import Image from '@components/image';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledText = styled(Text)`
  margin-bottom: 0;
`;

export const Background = styled.div<{ bgColor?: string }>`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: var(--default);
`;

export const Eyebrow = styled(Text)`
  margin-bottom: 1.5rem;
`;

export const Container = styled.div`
  padding: 1rem 0;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  ${({ theme }) => `
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    ${generateScales('width', '8rem', '12rem')}
    ${generateScales('height', '8rem', '12rem')}

    border-radius: 100%;
    background: linear-gradient(${theme.colors.transparent}, ${
    theme.colors.transparent
  }) padding-box, linear-gradient(to bottom, ${theme.colors.transparent}, ${
    theme.colors.transparent
  }) border-box;  border: 1px solid transparent;
    overflow: hidden;

  }}
  `}
`;

export const StyledImage = styled(Image)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const ImageBorder = styled.div`
  ${({ theme }) => `
    border: 0.5rem solid ${theme.colors.bottleGreen};
    border-radius: 50%;
    width: 100%;
    height: 100%;
    overflow: hidden;
  `}
`;

export const Block = styled.div<{ itemsPerRow?: number }>`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.display};
  `}
`;

export const StyledGrid = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4rem;
    margin-top: 4rem;
    padding: 0;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      grid-gap: 3rem;
      grid-template-columns: repeat(3, 1fr)};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-gap: 2rem;
      grid-template-columns: repeat(2, 1fr)};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-gap: 1.5rem;
      grid-template-columns: repeat(2, 1fr)};
    }

    @media only screen and (max-width: ${theme.breakpoints.xs}) {
      justify-content: center;
      grid-gap: 1.5rem;
      grid-row-gap: 3rem;
      grid-template-columns: repeat(1, 75%)};
    }
  `}
`;

export const ModuleTitle = styled(Text)`
  text-align: center;
`;

export const SubTitle = styled(Text)`
  margin: 1rem 0 0;
  text-align: center;
`;

export const Title = styled(Text)`
  margin: 1rem 0 0;
  text-align: center;
`;

export const Description = styled(Text)`
  ${({ theme }) => `
    margin: 0.12rem 0 0;
    text-align: center;
    color: ${theme.colors.orinoco};
  `}
`;
