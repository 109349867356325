import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import { Link } from 'gatsby';
import Image from '@components/image';

export const StyledFooter = styled.footer<{ $isAlternativeColorProvided: boolean }>`
  ${({ theme, $isAlternativeColorProvided }) => `
    padding-bottom: 5rem;
    background: ${
      $isAlternativeColorProvided ? theme.colors.transparent : theme.colors.bottleGreen
    };
    color: ${$isAlternativeColorProvided ? theme.colors.bottleGreen : theme.colors.white};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${theme.colors.jungleGreen};
    width: 100%;
    height: 100%;
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop)}
    font-family: ${theme.fontFamily.text};
    font-weight: 500;
    padding-top: 2.5rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column-reverse;
    }
  `}
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.lg}) {
      margin-top: 2rem;
    }
  `}
`;

export const Logo = styled(Image)`
  height: 1.5rem;
  margin-right: 0.5rem;
  width: auto;
  display: block;
`;

export const Links = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
    max-width: 100%;

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      row-gap: 2rem;
    }
  `}
`;

export const MetaLinksContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
  max-width: 100%;
  flex-wrap: wrap;
  row-gap: 1rem;
  justify-content: center;
`;

export const LinkStyles = css`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.text};
    font-weight: 500;
    font-size: ${theme.fontSize.small.desktop};
    line-height: ${theme.lineHeights.sm};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const StyledLink = styled(Link)<{ $isAlternativeColorProvided: boolean }>`
  ${({ theme, $isAlternativeColorProvided }) => css`
    color: ${$isAlternativeColorProvided ? theme.colors.bottleGreen : theme.colors.white};
    ${LinkStyles}
  `}
`;

export const StyledExternalLink = styled.a<{ $isAlternativeColorProvided: boolean }>`
  ${({ theme, $isAlternativeColorProvided }) => css`
    color: ${$isAlternativeColorProvided ? theme.colors.bottleGreen : theme.colors.white};
    ${LinkStyles}
  `}
`;

export const SocialMediasContainer = styled.div`
  display: flex;
  column-gap: 1.5rem;
  align-items: center;
`;

export const SocialMedia = styled.a`
  display: flex;
  height: auto;
  max-width: 1.75rem;
  text-decoration: none;
  :nth-of-type(1) {
    margin: 0;
  }
`;

export const SOC2LogoImage = styled(Image)`
  height: 4rem;
  width: 4rem;
`;
