import { useEffect } from 'react';

const useScrollToSection = (sectionId: string | undefined): void => {
  const location = typeof window !== 'undefined' ? window.location : undefined;
  const currentSectionId = new URLSearchParams(location?.search ?? '').get('section');

  useEffect(() => {
    if (currentSectionId && currentSectionId === sectionId) {
      const e = document.getElementById(sectionId);
      if (e) {
        let offsetTop = e?.offsetTop;
        let elem: Element = e;
        while ('offsetParent' in elem && elem.offsetParent) {
          elem = elem.offsetParent as Element;
          if ('offsetTop' in elem) {
            offsetTop += (elem as HTMLElement).offsetTop;
          }
        }
        window.scrollTo({ top: Math.max(0, offsetTop - 95), behavior: 'smooth' });
      }
    }
  }, [currentSectionId, sectionId]);
};

export default useScrollToSection;
