import React from 'react';
import { defaultTheme } from '@styles/theme.styled';
import { StyledSVG } from './svg-icon.styled';

interface ButtonProps {
  color?: keyof typeof defaultTheme.colors;
  width?: number;
  height?: number;
  className?: string;
}

const Arrow = ({
  color = 'jungleGreen',
  width = 48,
  height = 48,
  className = '',
}: ButtonProps): JSX.Element => (
  <StyledSVG
    color={color}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke={color}
      strokeWidth={defaultTheme.borderWidths.icons}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M12 16L16 12L12 8"
      stroke={color}
      strokeWidth={defaultTheme.borderWidths.icons}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M8 12H16"
      stroke={color}
      strokeWidth={defaultTheme.borderWidths.icons}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </StyledSVG>
);

export default Arrow;
