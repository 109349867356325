import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { RichTextField, RTNode } from '@prismicio/types';
import Image, { ImageProps } from '@components/image';
import {
  Container,
  ImageContainer,
  VideoContainer,
  RichTextContainer,
} from './paragraphs.shared.styled';

export interface PrimaryProps {
  image: ImageProps;
  rich_text: {
    richText: RichTextField;
  };
  video: {
    html: string;
  };
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextField;
  };
  subheading?: {
    richText: RichTextField;
  };
}

const renderParagraphs = (type: string, primary: PrimaryProps): JSX.Element | null => {
  switch (type) {
    case 'rich_text_content': {
      const sections: RichTextField = (primary?.rich_text?.richText as RTNode[]).reduce(
        (acc: RTNode[], curr: RTNode) => {
          if (acc.length || curr) {
            acc.push(curr);
          }
          return acc;
        },
        []
      ) as RichTextField;
      if (sections.length > 0) {
        return (
          <RichTextContainer>
            <PrismicRichText field={sections} />
          </RichTextContainer>
        );
      }
      return null;
    }
    case 'image': {
      if (primary.image?.url) {
        return (
          <Container>
            <ImageContainer>
              <Image
                gatsbyImageData={primary.image.gatsbyImageData}
                url={primary.image.url}
                alt={primary.image.alt}
              />
            </ImageContainer>
          </Container>
        );
      }
      return null;
    }
    case 'video': {
      if (primary.video?.html) {
        return (
          <Container>
            <VideoContainer dangerouslySetInnerHTML={{ __html: primary.video.html }} />
          </Container>
        );
      }
      return null;
    }
    default:
      return null;
  }
};

export default renderParagraphs;
