import React from 'react';
import Slider from 'react-slick';
import Section from '@components/section';
import { ImageProps } from '@components/image';
import { Container, SlickContainer, SlickImage, Dots } from './carousel.styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface CarouselItemProps {
  image?: ImageProps;
}

interface CarouselProps {
  items?: CarouselItemProps[];
}

const Carousel = ({ items = [] }: CarouselProps): JSX.Element => {
  const settings = {
    dots: true,
    // eslint-disable-next-line react/no-unstable-nested-components
    appendDots: (dots: JSX.Element) => <Dots>{dots}</Dots>,
    speed: 300,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    swipe: true,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  if (items?.length === 0 || !items[0].image?.url) {
    return <></>;
  }

  return (
    <Section backgroundColor="white">
      <Container>
        <Slider {...settings}>
          {items?.map(({ image }) => (
            <SlickContainer key={image?.url}>
              <SlickImage url={image?.url} alt={image?.alt} />
            </SlickContainer>
          ))}
        </Slider>
      </Container>
    </Section>
  );
};

export default Carousel;
