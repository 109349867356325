import styled from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { MediumCard } from '@styles/cards.styled';
import Section from '@components/section';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledSection = styled(Section)`
  padding-top: 4rem;
  padding-bottom: 0;
`;

export const Content = styled.div`
  ${({ theme }) => `
    z-index: ${theme.zIndex.layeredContent};
    position: relative;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 75%;
    }
  `}
`;

export const ColumnWrapper = styled.div`
  ${({ theme }) => `
    // grid setup
    display: grid;
    grid-template-columns: 30rem  1fr;
    column-gap: ${theme.spacing.columnGap};
    align-items: center;

    // offset content
    transform: translateY(${theme.spacing.md});

    // display above any overlapping graphics
    position: relative;
    z-index: ${theme.zIndex.layeredContent};


    // responsive grid sizes

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      grid-template-columns: 28rem  1fr;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      grid-template-columns: 24rem  1fr;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      // padding-top: 4rem;
      grid-template-columns: 1fr;
      grid-auto-flow: dense;
      row-gap: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      // padding-top: 4rem;
      grid-template-columns: 1fr;
      grid-auto-flow: dense;
      row-gap: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding-top: 0rem;
      row-gap: 2rem;

      // offset content
      transform: translateY(0);
    }
  `}
`;

export const TerminalContent = styled.div`
  ${MediumCard}

  position: relative;
  background: linear-gradient(
      128deg,
      rgba(238, 57, 50, 0.2) 0%,
      rgba(68, 200, 225, 0.116) 62.5%,
      rgba(0, 0, 0, 0) 100%
    ),
    #13294e;
  text-align: center;
  justify-content: center;
`;

export const InstructionWrapper = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing.md} 0;
  `}
`;

export const StyledText = styled.p<{ textColor: keyof typeof defaultTheme.colors }>`
  ${({ theme, textColor }) => `
    font-family: ${theme.fontFamily.text};
    color: ${theme.colors[textColor]};
    line-height: ${theme.lineHeights.base};

    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop)}
  `}
`;
