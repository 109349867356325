import React from 'react';
import { RichTextField } from '@prismicio/types';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { StyledArrow } from '@components/button/button.styled';
import { defaultTheme } from '@styles/theme.styled';
import generateId from '@helpers/generate-id';
import Image, { ImageProps } from '@components/image';
import {
  Card,
  Content,
  StyledText,
  StyledExternalLink,
  ImageContainer,
} from './follow-the-link.styled';

const FollowTheLinkIdGenerator = generateId();

interface FollowTheLinkProps {
  outlined?: boolean;
  variant?: keyof typeof defaultTheme.buttonVariants;
  moduleTitle?: string;
  description?: RichTextField;
  buttonLabel?: string;
  buttonUrl?: string;
  image?: ImageProps;
}

const FollowTheLink = ({
  outlined = true,
  variant = 'dark',
  moduleTitle,
  description = [],
  buttonLabel,
  buttonUrl,
  image,
}: FollowTheLinkProps): JSX.Element => {
  const hasDescription: boolean = description?.length > 0 && description[0].text !== '';

  if (!moduleTitle && !hasDescription && !(buttonLabel && buttonUrl)) {
    return <></>;
  }

  return (
    <Section backgroundColor="white">
      <Wrapper widthSize="md" alignment="center">
        <Card>
          <Content>
            <StyledText
              textColor="dark"
              textSize="heading4"
              type="title"
              stringText={moduleTitle}
            />
            {hasDescription && <Text type="description" richText={description} />}
            <ImageContainer key={FollowTheLinkIdGenerator.next().value}>
              <Image gatsbyImageData={image?.gatsbyImageData} url={image?.url} alt={image?.alt} />
            </ImageContainer>
            {buttonUrl && buttonLabel && (
              <StyledExternalLink
                href={buttonUrl}
                rel="noreferrer"
                variant={variant}
                outlined={outlined}
              >
                {buttonLabel}
                <StyledArrow />
              </StyledExternalLink>
            )}
          </Content>
        </Card>
      </Wrapper>
    </Section>
  );
};

export default FollowTheLink;
