exports.linkResolver = (doc) => {
  switch (doc?.type) {
    case 'blog':
      return `/blog`;
    case 'full_page_video':
      return doc.uid ? `/video/${doc.uid}` : '/';
    case 'page':
      return doc.uid && doc.uid !== 'home' ? `/${doc.uid}` : '/';
    case 'page_404':
      return `/daodasd`;
    case 'post':
      return doc.uid ? `/blog/${doc.uid}` : '/';
    case 'support':
      return '/support';
    case 'security':
      return '/security';
    default:
      return '/';
  }
}
