import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledSubheading } from './subheading.styled';

const Subheading = ({
  textColor = 'bottleGreen',
  textSize = 'subheading',
  richText,
  className,
}: TextComponentProps): JSX.Element => (
  <StyledSubheading textSize={textSize} textColor={textColor} className={className}>
    <PrismicRichText field={richText} />
  </StyledSubheading>
);

export default Subheading;
