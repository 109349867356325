import React from 'react';

interface ShareIconProps {
  fill?: string;
}

const ShareIcon = ({ fill }: ShareIconProps): JSX.Element => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 6C13.7426 6 14.75 4.99264 14.75 3.75C14.75 2.50736 13.7426 1.5 12.5 1.5C11.2574 1.5 10.25 2.50736 10.25 3.75C10.25 4.99264 11.2574 6 12.5 6Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 11.25C4.74264 11.25 5.75 10.2426 5.75 9C5.75 7.75736 4.74264 6.75 3.5 6.75C2.25736 6.75 1.25 7.75736 1.25 9C1.25 10.2426 2.25736 11.25 3.5 11.25Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 16.5C13.7426 16.5 14.75 15.4926 14.75 14.25C14.75 13.0074 13.7426 12 12.5 12C11.2574 12 10.25 13.0074 10.25 14.25C10.25 15.4926 11.2574 16.5 12.5 16.5Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.44238 10.1328L10.5649 13.1178"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5574 4.88281L5.44238 7.86781"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShareIcon;
