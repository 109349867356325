import React, { useEffect, useRef, useState } from 'react';
import { RichTextField } from '@prismicio/types';
import Image, { ImageProps } from '@components/image';
import { defaultTheme } from '@styles/theme.styled';
import BGImage from '@images/hero-mask-background.svg';
import MobileBGImage from '@images/hero-mask-mobile-background.svg';
import TabletBGImage from '@images/hero-mask-tablet-background.svg';
import HeroMask from '@images/hero-mask.svg';
import { StyledColorLink } from '@components/call-to-action/call-to-action.styled';
import SubtleSlideFade from '@components/animations/SubtleSlideFade';
import { Fade } from 'react-awesome-reveal';
import {
  StyledWrapper,
  StyledSubheading,
  StyledDescription,
  Header,
  TextContainer,
  ImageContainer,
  FloatingBackgroundImage,
  FloatingMobileBackgroundImage,
  FloatingTabletBackgroundImage,
  FloatingMask,
  PlacerMask,
  MaskedBackgroundImage,
  PlacerMobileMask,
  PlacerTabletMask,
  EmptyHeader,
  MaskedBackgroundVideo,
  StyledTitle,
  Gradient1,
  BubbleWrapper,
  LinksContainer,
} from './page-hero.styled';

export interface PageHeroProps {
  centerTitle?: boolean;
  centerAll?: boolean;
  title?: string;
  description?: RichTextField;
  subheading?: RichTextField;
  image?: ImageProps;
  linkUrl?: {
    url: string;
    target?: string;
    document?: {
      uid: string;
    };
  };
  video?: {
    url: string;
  };
  linkLabel?: string;
  imageLayout: 'inline' | 'masked';
  backgroundColor?: keyof typeof defaultTheme.colors;
  secondaryLinkLabel?: string;
  secondaryLinkUrl?: {
    url: string;
    target?: string;
    document?: {
      uid: string;
    };
  };
  secondaryLinkColor?: string;
  secondaryLinkHoverColor?: string;
  videoPoster?: {
    url: string;
  };
  gradientBackground: boolean;
  home: boolean;
}

const PageHero = ({
  title,
  subheading = [],
  description = [],
  image,
  video,
  videoPoster,
  linkLabel,
  linkUrl,
  imageLayout = 'inline',
  backgroundColor,
  centerTitle,
  centerAll,
  secondaryLinkLabel,
  secondaryLinkUrl,
  secondaryLinkColor,
  secondaryLinkHoverColor,
  gradientBackground,
  home,
}: PageHeroProps): JSX.Element => {
  const isSubheadingProvided =
    subheading?.length > 0 && subheading[0] && 'text' in subheading[0] && subheading[0].text;
  const isDescriptionProvided =
    description?.length > 0 && description[0] && 'text' in description[0] && description[0].text;
  const isImageProvided = !!(image?.gatsbyImageData || image?.url);
  const isInlineImage = imageLayout === 'inline';
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const setPlayBack = () => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5;
    }
  };
  const [loadAssets, setloadAssets] = useState(false);

  useEffect(() => {
    setloadAssets(true);
  }, []);

  if (!title && !isSubheadingProvided) {
    return <EmptyHeader isInline={isInlineImage} hasImage={isImageProvided} />;
  }

  let url = linkUrl?.url;
  if (linkUrl?.document) {
    url = linkUrl.document.uid === 'home' ? '/' : `/${linkUrl.document.uid}`;
  }

  let secondaryUrl = secondaryLinkUrl?.url;
  if (secondaryLinkUrl?.document) {
    secondaryUrl =
      secondaryLinkUrl.document.uid === 'home' ? '/' : `/${secondaryLinkUrl.document.uid}`;
  }

  const windowAvailable = typeof window !== 'undefined';
  const showVideo =
    windowAvailable &&
    video?.url &&
    window.innerWidth > parseInt(defaultTheme.breakpoints.md.replace('px', ''), 10);

  return (
    <Header
      className="page-hero"
      isInline={isInlineImage}
      hasImage={isImageProvided}
      $bgColor={backgroundColor}
    >
      {gradientBackground && (
        <BubbleWrapper hasImage={false}>
          <Gradient1 />
        </BubbleWrapper>
      )}
      <StyledWrapper hasImage={isImageProvided}>
        <TextContainer hasImage={isImageProvided} isInline={isInlineImage} $isHomePage={home}>
          <SubtleSlideFade direction="bottom" triggerOnce verySubtle>
            <>
              {title && (
                <StyledTitle
                  tag="h1"
                  textSize="heading1"
                  type="title"
                  stringText={title}
                  $center={centerAll || centerTitle}
                  $isHomePage={home}
                />
              )}
              {isSubheadingProvided && (
                <StyledSubheading type="subheading" richText={subheading} center={centerAll} />
              )}
              {isDescriptionProvided && (
                <StyledDescription textSize="body" richText={description} center={centerAll} />
              )}
            </>
          </SubtleSlideFade>
          <Fade triggerOnce style={{ opacity: 0 }}>
            <LinksContainer>
              {linkLabel && url && (
                <StyledColorLink title={linkLabel} to={url} $center={centerAll}>
                  {linkLabel}
                </StyledColorLink>
              )}
              {secondaryLinkLabel && secondaryUrl && (
                <StyledColorLink
                  title={secondaryLinkLabel}
                  to={secondaryUrl}
                  $center={centerAll}
                  $primaryColor={secondaryLinkColor}
                  $hoverColor={secondaryLinkHoverColor}
                >
                  {secondaryLinkLabel}
                </StyledColorLink>
              )}
            </LinksContainer>
          </Fade>
        </TextContainer>
        {isImageProvided && isInlineImage && !showVideo && loadAssets && (
          <ImageContainer>
            <Image gatsbyImageData={image?.gatsbyImageData} url={image?.url} alt="" />
          </ImageContainer>
        )}
      </StyledWrapper>
      {!isInlineImage && loadAssets && (
        <>
          <FloatingBackgroundImage src={BGImage} alt="" />
          <FloatingMobileBackgroundImage src={MobileBGImage} alt="" />
          <FloatingTabletBackgroundImage src={TabletBGImage} alt="" />
          <FloatingMask>
            {windowAvailable && !showVideo && <MaskedBackgroundImage src={image?.url} alt="" />}
            {showVideo && (
              <MaskedBackgroundVideo
                ref={videoRef}
                autoPlay
                muted
                loop
                playsInline
                onCanPlay={() => setPlayBack()}
                poster={videoPoster?.url}
              >
                <source src={video?.url} type="video/mp4" />
                Your browser does not support the video tag.
              </MaskedBackgroundVideo>
            )}
            <PlacerMask src={HeroMask} alt="" />
            <PlacerMobileMask src={MobileBGImage} alt="" />
            <PlacerTabletMask src={TabletBGImage} alt="" />
          </FloatingMask>
        </>
      )}
    </Header>
  );
};

export default PageHero;
