import React from 'react';
import { RichTextField } from '@prismicio/types';
import Text, { TextHead } from '@components/text';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { ImageProps } from '@components/image';
import { defaultTheme } from '@styles/theme.styled';
import generateId from '@helpers/generate-id';
import getContrastYIQ from '@helpers/get-color-contrast';
import Arrow from '@components/svg-icons/arrow';
import useScrollToSection from '@hooks/use-scroll-to-section';
import SubtleSlideFade from '@components/animations/SubtleSlideFade';
import {
  GridBlock,
  FlexContainer,
  StyledGrid,
  StyledBlock,
  StyledTitle,
  StyledHeading,
  StyledSubheading,
  StyledDescription,
  StyledExternalLink,
  LeftStyledBlock,
  StyledBackgroundShape,
} from './feature-grid.styled';

const featureGridIdGenerator = generateId();

const BackgroundShape = () => {
  return (
    <StyledBackgroundShape
      // width="2305"
      // height="3142"
      viewBox="0 0 2305 3142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3058H1112C1829 3058 2221 2736 2221 2233.5C2221 1731 1378 1559.5 1371 1104.5C1364 649.5 1766.5 732.5 1766.5 427.5C1766.5 122.5 1445.5 84.0002 1109 84H0"
        stroke="#61D16F"
        strokeWidth="168"
      />
    </StyledBackgroundShape>
  );
};

export interface FeatureGridItemType {
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextField;
  };
}

interface FeatureGridProps {
  start_full_page_background?: boolean;
  id?: string;
  eyebrow?: string;
  title?: string;
  subheading?: RichTextField;
  link?: {
    url: string;
    target: string;
  };
  linkLabel?: string;
  backgroundColor?: keyof typeof defaultTheme.colors;
  backgroundImage?: ImageProps;
  backgroundPosition?: string;
  itemsPerRow?: string;
  items?: FeatureGridItemType[];
}

const FeatureGrid = ({
  start_full_page_background,
  id,
  eyebrow,
  title,
  subheading,
  link,
  linkLabel = '',
  backgroundColor = 'white',
  backgroundImage,
  backgroundPosition = 'left',
  itemsPerRow = '2',
  items = [],
}: FeatureGridProps) => {
  useScrollToSection(id);
  const textColor = getContrastYIQ(defaultTheme.colors[backgroundColor]);
  const layoutType = ((): 'row' | 'column' => (+itemsPerRow === 2 ? 'row' : 'column'))();

  const renderHeader = (): JSX.Element => (
    <>
      {!!eyebrow && (
        <Text type="eyebrow" stringText={eyebrow} textSize="eyebrow" textColor={textColor} />
      )}
      {!!title && (
        <StyledHeading
          type="title"
          tag="h2"
          textSize="heading2"
          stringText={title}
          textColor={textColor}
        />
      )}
      {!!subheading?.length && (
        <StyledSubheading type="subheading" richText={subheading} textColor={textColor} />
      )}
      {Boolean(link?.url) && (
        <StyledExternalLink href={link?.url} target={link?.target}>
          {linkLabel}
          <Arrow />
        </StyledExternalLink>
      )}
    </>
  );

  return (
    <>
      <Section
        id={id}
        backgroundColor={backgroundColor}
        backgroundImageUrl={backgroundImage?.url}
        backgroundPosition={backgroundPosition}
      >
        <Wrapper>
          <FlexContainer layout={layoutType}>
            {layoutType === 'column' ? (
              <TextHead align="left">{renderHeader()}</TextHead>
            ) : (
              <LeftStyledBlock>{renderHeader()}</LeftStyledBlock>
            )}
            <GridBlock>
              <StyledGrid itemsPerRow={+itemsPerRow}>
                <SubtleSlideFade direction="bottom" cascade triggerOnce>
                  {items?.map((item) => (
                    <StyledBlock key={featureGridIdGenerator.next().value} textColor={textColor}>
                      {!!item.title?.text && (
                        <StyledTitle
                          type="title"
                          stringText={item.title.text}
                          textSize="heading6"
                          textColor={textColor}
                        />
                      )}
                      {!!item.description?.richText && (
                        <StyledDescription
                          type="description"
                          richText={item.description.richText}
                          textSize="body"
                          textColor={textColor}
                        />
                      )}
                    </StyledBlock>
                  ))}
                </SubtleSlideFade>
              </StyledGrid>
            </GridBlock>
          </FlexContainer>
        </Wrapper>
      </Section>
      {start_full_page_background && (
        <Wrapper>
          <BackgroundShape />
        </Wrapper>
      )}
    </>
  );
};

export default FeatureGrid;
