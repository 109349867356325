import React from 'react';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledTitle } from './title.styled';

const Title = ({
  margin = 'sm',
  textColor = 'bottleGreen',
  textSize = 'heading3',
  tag = 'h1',
  stringText = '',
  className,
}: TextComponentProps): JSX.Element => {
  return (
    <StyledTitle
      margin={margin}
      textSize={textSize}
      textColor={textColor}
      className={className}
      as={tag}
    >
      {stringText}
    </StyledTitle>
  );
};

export default Title;
