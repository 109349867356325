/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '@components/global-style';
import Header from '@components/header';
import Footer from '@components/footer';
import { defaultTheme } from '@styles/theme.styled';
import CookiePrompt from '@components/cookie-consent';
import { Container, Content } from './default-layout.styled';
import '../styles/typography.css';

interface LayoutProperties {
  isAlternativeHeaderProvided?: boolean;
  isAlternativeFooterProvided?: boolean;
  showBanner?: boolean;
  children: ReactNode;
}

const Layout = ({
  isAlternativeHeaderProvided,
  isAlternativeFooterProvided,
  showBanner,
  children,
}: LayoutProperties): JSX.Element => (
  <ThemeProvider theme={defaultTheme}>
    <Container>
      <GlobalStyle />
      <CookiePrompt />
      <Header isAlternativeHeaderProvided={isAlternativeHeaderProvided} showBanner={showBanner} />
      <Content>{children}</Content>
      <Footer isAlternativeFooterProvided={isAlternativeFooterProvided} />
    </Container>
  </ThemeProvider>
);

export default Layout;
