import styled from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';

export const ChromeFrame = styled.div`
  ${({ theme }) => `
    box-shadow: ${theme.shadows.normal};
    background-color: #070D19;
    border-radius: ${theme.borderRadius.small};
    overflow: hidden;
  `}
`;

export const ChromeControls = styled.div`
  ${({ theme }) => `
    display: flex;
    padding: 1rem 1rem;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.orinoco};
  `}
`;

export const ControlButton = styled.div`
  ${({ theme }) => `
    border-radius: 0.375rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: 0.675rem;
      width: 0.675rem;
    }
  `}
`;

export const CloseControl = styled(ControlButton)`
  background-color: #ee3932;
`;

export const MinimizeControl = styled(ControlButton)`
  background-color: #c6a0e2;
`;

export const MaximizeControl = styled(ControlButton)`
  ${({ theme }) => `
    background-color: ${theme.colors.orinoco};
  `}
`;

export const ChromeBody = styled.div`
  display: flex;
  position: relative;
`;

export const LinesWrapper = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    bottom: 0;
    left; 0;
    text-align: right;
    padding: 0.75rem;
    border-right: 1px solid ${theme.colors.orinoco};

    &:after {
      display: block;
      content: '';
      position: absolute;
      background-color: #070D19;
      bottom: 0;
      left: 0.75rem;
      right: 0.75rem;
      height: 0.75rem;
    }
  `}
`;

export const Line = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.mono};
    color: ${theme.colors.orinoco};
    line-height: ${theme.lineHeights.xl};

    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.body.desktop)}
  `}
`;

export const Code = styled.div`
  ${({ theme }) => `
    text-align: left;
    padding: 0.75rem 1rem;
    ${generateScales('padding-left', '3.75rem', '4rem')}
    font-family: ${theme.fontFamily.mono};
    color: ${theme.colors.white};
    line-height: ${theme.lineHeights.xl};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      word-break: break-all;
    }

    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.body.desktop)}
  `}
`;

export const Syntax = styled.span<{ textColor: keyof typeof defaultTheme.colors }>`
  ${({ theme, textColor }) => `
      color: ${theme.colors[textColor]};
  `}
`;
