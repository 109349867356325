import React from 'react';
import Image, { ImageProps } from '@components/image';
import generateId from '@helpers/generate-id';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';
import {
  StyledSection,
  EmptyState,
  PostsGrid,
  StyledGatsbyLink,
  StyledTitle,
  ImageContainer,
  Meta,
  Category,
  Description,
  NoImage,
} from './posts-list.shared.styled';

const postsListIdGenerator = generateId();

export interface PostsListProps {
  edges?: {
    node: {
      uid: string;
      last_publication_date: string;
      tags: string[];
      data: {
        post_title: {
          text: string;
        };
        post_description: {
          text: string;
        };
        post_preview_image: ImageProps;
      };
    };
  }[];
  title?: string;
}

const PostsList = ({ edges = [], title }: PostsListProps): JSX.Element => (
  <StyledSection backgroundColor="white">
    {title && <StyledTitle textSize="heading4" type="title" stringText={title} />}
    <Wrapper>
      {edges.length > 0 ? (
        <PostsGrid>
          {edges.map(({ node }) => {
            if (!node.data.post_title.text) {
              return null;
            }
            return (
              <div key={postsListIdGenerator.next().value}>
                <StyledGatsbyLink to={`/blog/${node.uid}`}>
                  <ImageContainer>
                    {node.data.post_preview_image.url ? (
                      <Image
                        gatsbyImageData={node.data.post_preview_image.gatsbyImageData}
                        url={node.data.post_preview_image.url}
                        alt={node.data.post_preview_image.alt}
                      />
                    ) : (
                      <NoImage />
                    )}
                  </ImageContainer>
                </StyledGatsbyLink>
                <Meta>
                  {node.tags?.length > 0 && (
                    <span>
                      {node.tags?.map(
                        (tag: string) =>
                          tag && (
                            <Category key={postsListIdGenerator.next().value}>{tag}&nbsp;</Category>
                          )
                      )}
                    </span>
                  )}
                </Meta>
                <Text
                  type="title"
                  tag="h3"
                  textSize="heading6"
                  stringText={node.data.post_title.text}
                />
                {node.data.post_description.text && (
                  <Description>{node.data.post_description.text}</Description>
                )}
              </div>
            );
          })}
        </PostsGrid>
      ) : (
        <EmptyState>No posts found.</EmptyState>
      )}
    </Wrapper>
  </StyledSection>
);

export default PostsList;
