import styled from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';
import Text from '@components/text';
import Section from '@components/section';

export const Container = styled(Section)`
  text-align: center;
`;

export const Content = styled.div`
  ${({ theme }) => `
    position: relative;
    z-index: ${theme.zIndex.layeredContent};
    justify-content: center;
  `}
`;

export const Title = styled(Text)`
  text-align: center;
`;

export const Description = styled(Text)`
  text-align: center;
`;

export const WrapContainer = styled.div`
  ${({ theme }) => `
    padding-top: ${theme.padding.cards.md};
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      grid-gap: 1rem;
    }

    @media (max-width: ${theme.breakpoints.xs}) {
      grid-template-columns: repeat(1, 1fr);
    }
  `}
`;

export const Block = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(${theme.colors.bottleGreen}, ${theme.colors.bottleGreen}) padding-box,
      linear-gradient(to bottom, ${theme.colors.orinoco}, ${theme.colors.jungleGreen}) border-box;
    border: 1px solid transparent;
    border-radius: ${theme.borderRadius.small};

    :hover {
      background: linear-gradient(rgba(19, 41 ,78, 0.9), rgba(19, 41 ,78, 0.9)) padding-box,
      linear-gradient(to bottom, ${theme.colors.orinoco}, ${theme.colors.jungleGreen}) border-box;
    }
  `}
`;

export const StyledLink = styled.a`
  ${({ theme }) => {
    const padding = '3rem';
    return `
    padding: ${padding} 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: ${theme.fontFamily.text};
    color: ${theme.colors.white};
    line-height: ${theme.lineHeights.lg};
    text-decoration: none;

    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop)}

    @media (max-width: ${theme.breakpoints.xl}) {
      padding: calc(${padding} * 0.9375) 0;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: calc(${padding} * 0.9375) 0;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: calc(${padding} * 0.875) 0;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      padding: calc(${padding} * 0.8125) 0;
    }

    @media (max-width: ${theme.breakpoints.xs}) {
      padding: calc(${padding} * 0.75) 0;
    }
  `;
  }}
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => `
    max-width: 3.5rem;

    @media (max-width: ${theme.breakpoints.sm}) {
      max-width: 2.5rem;
    }
  `}
`;

export const StyledLabel = styled.p`
  margin-top: 1.25rem;
  width: 100%;
`;
