import styled, { css } from 'styled-components';
import { headerHeight } from '@components/header/header.styled';
import BGImage from '@images/hero-background.svg';
import HeroMask from '@images/hero-mask.svg';
import HeroTabletMask from '@images/hero-tablet-mask.svg';
import HeroMobileMask from '@images/hero-mobile-mask.svg';
import Text from '@components/text';
import Image from '@components/image';
import Wrapper from '@components/wrapper.styled';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledTitle = styled(Text)<{ $center?: boolean; $isHomePage?: boolean }>`
  ${({ $center, theme, $isHomePage }) => `
    ${$center ? 'text-align: center;' : ''}
    ${$isHomePage ? 'font-size: 6.25rem;' : ''};
    color: ${theme.colors.bottleGreen};
    line-height: 5.6rem;
    letter-spacing: -3px;
    ${
      $isHomePage
        ? `
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      font-size: 3.25rem;
      text-align: center;
      line-height: unset;
    }
    `
        : `
        @media only screen and (max-width: ${theme.breakpoints.lg}) {
          text-align: center;
        }
        `
    }
}
  `}
`;

export const StyledSubheading = styled(Text)<{ center?: boolean }>`
  ${({ center, theme }) => `
    ${center ? 'text-align: center;' : ''}
    display: block;
    ${generateScales('margin-top', '1rem', '3.5rem')}
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      text-align: center;
    }
  `}
`;

export const StyledDescription = styled(Text)<{ center?: boolean }>`
  display: block;
  ${generateScales('margin-top', '1rem', '1.5rem')}

  ${({ theme }) => `
  @media only screen and (max-width: ${theme.breakpoints.lg}) {
    text-align: center;
  }
  `}
`;

interface WrapperProps {
  hasImage: boolean;
}

export const StyledWrapper = styled(Wrapper)<WrapperProps>`
  z-index: 3;
  position: relative;
`;

interface HeaderProps {
  isInline: boolean;
  hasImage: boolean;
  $bgColor?: string;
}

export const EmptyHeader = styled.div<HeaderProps>`
  ${({ isInline, hasImage }) =>
    isInline &&
    hasImage &&
    css`
      background-image: url(${BGImage});
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: bottom center;
    `}

  width: 100%;
  position: relative;
  ${generateScales('padding-top', '2rem', '8rem')}

  &::before {
    content: '';
    display: block;
    height: 2rem;
    width: 1px;
  }

  ${({ theme, $bgColor, isInline, hasImage }) => css`
    background-color: ${$bgColor ?? 'white'};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      ${hasImage &&
      !isInline &&
      css`
        padding-bottom: 20rem;
      `}

      &::before {
        height: 2rem;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      ${hasImage &&
      !isInline &&
      css`
        padding-bottom: 14rem;
      `}
    }
  `}
`;

export const Header = styled.header<HeaderProps>`
  ${({ isInline, hasImage, $bgColor }) => `
    ${
      isInline && hasImage
        ? `
        background-image: url(${BGImage});
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: bottom center;
      `
        : ''
    }
    background-color: ${$bgColor ?? '#b2e6b5'};
`}

  width: 100%;
  position: relative;

  ${generateScales('padding-top', '2rem', '8rem')}
  ${generateScales('padding-bottom', '2rem', '8rem')}

  &::before {
    content: '';
    display: block;
    height: ${headerHeight};
    width: 1px;
  }

  ${({ theme, isInline, hasImage }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      ${hasImage &&
      !isInline &&
      css`
        padding-bottom: 20rem;
      `}

      &::before {
        height: 5rem;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      ${hasImage &&
      !isInline &&
      css`
        padding-bottom: 14rem;
      `}
    }
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    padding-left: 2rem;
    position: absolute;
    top: calc(50%);
    left: calc(50% - (${theme.maskWrappers.xxl} / 2) + 50% + (${theme.spacing.wrapperGap} * 4));
    transform: translateY(-50%);
    width: calc(50% - ${theme.spacing.wrapperGap});
    height: 100%;
    min-height: 24rem;
    justify-content: stretch;
    align-items: stretch;
    overflow: hidden;

    div {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      left: calc(50% - (${theme.maskWrappers.xl} / 2) + 50% + (${theme.spacing.wrapperGap} * 4));
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: calc(50% - (${theme.maskWrappers.lg} / 2) + 50% + (${theme.spacing.wrapperGap} * 4));
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      width: 100%;
      height: auto;
      margin: 0 auto;
      margin-top: 3rem;
      justify-content: center;
      padding-left: 0;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      min-height: unset;
    }
  `}
`;

interface TextContainerProps {
  hasImage: boolean;
  isInline: boolean;
  $isHomePage: boolean;
}

export const TextContainer = styled.div<TextContainerProps>`
  ${({ theme, hasImage, isInline, $isHomePage }) => css`
    margin: ${hasImage ? 'unset' : '0 auto'};
    text-align: ${hasImage ? 'left' : 'center'};
    max-width: ${hasImage ? '50%' : '100%'};

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      max-width: 50%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 50%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: ${isInline || $isHomePage ? '100%' : '75%'};
      ${$isHomePage ? 'margin-bottom: 5rem;' : ''}
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 100%;
      + ${ImageContainer} {
        margin-top: 2rem;
      }
      ${$isHomePage ? 'margin-bottom: 1rem;' : ''}
    }
  `}
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 72rem;

  div {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export const FloatingBackgroundImage = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  height: auto;

  ${({ theme }) => css`
    left: calc(50% - (${theme.maskWrappers.xxl} / 2) + ${theme.spacing.wrapperGap});
    width: calc(${theme.maskWrappers.xxl} * 2);

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      left: calc(50% - (${theme.maskWrappers.xl} / 2) + ${theme.spacing.wrapperGap});
      width: calc(${theme.maskWrappers.xl} * 2);
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: calc(50% - (${theme.maskWrappers.lg} / 2) + ${theme.spacing.wrapperGap});
      width: calc(${theme.maskWrappers.lg} * 2);
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const FloatingMobileBackgroundImage = styled.img`
  ${({ theme }) => css`
    position: absolute;
    z-index: 1;
    top: calc(100% - 14rem);
    height: auto;
    width: 100%;
    left: 0;
    display: none;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: unset;
    }
  `}
`;

export const FloatingTabletBackgroundImage = styled.img`
  ${({ theme }) => css`
    position: absolute;
    z-index: 1;
    top: calc(100% - 24rem);
    height: auto;
    width: 200%;
    left: 0;
    display: none;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: unset;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      top: calc(100% - 20rem);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const FloatingMask = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  mask-image: url(${HeroMask});
  mask-size: 100% 100%;
  mask-repeat: no-repeat;

  ${({ theme }) => css`
    left: calc(50% - (${theme.maskWrappers.xxl} / 2) + ${theme.spacing.wrapperGap});

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      left: calc(50% - (${theme.maskWrappers.xl} / 2) + ${theme.spacing.wrapperGap});
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: calc(50% - (${theme.maskWrappers.lg} / 2) + ${theme.spacing.wrapperGap});
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      top: calc(100% - 24rem);
      left: 0;
      mask-image: url(${HeroTabletMask});
      width: 200%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      top: calc(100% - 20rem);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      top: calc(100% - 14rem);
      left: 0;
      mask-image: url(${HeroMobileMask});
      width: 100%;
    }
  `}
`;

export const MaskedBackgroundImage = styled.img`
  position: absolute;
  top: 0;
  height: 50%;
  width: auto;

  ${({ theme }) => css`
    left: calc(${theme.maskWrappers.xxl});
    transform: translateX(-100%);

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      left: calc(${theme.maskWrappers.xl});
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: calc(${theme.maskWrappers.lg});
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      top: 15%;
      left: 50%;
      width: auto;
      height: 90%;
      transform: translateY(-50%) translateX(-100%);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      top: 10%;
      left: 105%;
      width: auto;
      height: 85%;
      transform: translateY(-50%) translateX(-100%);
    }
  `}
`;

export const MaskedBackgroundVideo = styled.video`
  position: absolute;
  top: 188px;
  height: 579px;
  margin-left: 30%;
  width: auto;
  left: calc(800px);
  transform: translateX(-100%);
  left: calc(800px);
  transform: translateX(-100%);

  ${({ theme }) => css`
    // left: calc(${theme.maskWrappers.xxl});
    left: calc(800px);
    transform: translateX(-100%);

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      left: calc(${theme.maskWrappers.xl});
      top: 149px;
      height: 579px;
      margin-left: 12%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: calc(${theme.maskWrappers.lg});
      top: 109px;
      height: 483px;
      margin-left: 12%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      top: 29%;
      left: 59%;
      width: auto;
      height: 67%;
      transform: translateY(-50%) translateX(-100%);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      top: 21%;
      left: 126%;
      width: auto;
      height: 55%;
      transform: translateY(-50%) translateX(-100%);
    }
  `}
`;

export const PlacerMobileMask = styled.img`
  display: none;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: auto;
      width: 100%;
      opacity: 0;
      display: unset;
    }
  `}
`;

export const PlacerTabletMask = styled.img`
  display: none;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      height: auto;
      width: 100%;
      opacity: 0;
      display: unset;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: auto;
      width: 100%;
      opacity: 0;
      display: none;
    }
  `}
`;

export const PlacerMask = styled.img`
  ${({ theme }) => css`
    width: calc(${theme.maskWrappers.xxl} * 2);
    height: auto;
    opacity: 0;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      width: calc(${theme.maskWrappers.xl} * 2);
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: calc(${theme.maskWrappers.lg} * 2);
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

export const StyledVideo = styled.video`
  margin: 2rem 0;
  width: 100%;
  border-radius: 10px;
`;

export const BubbleWrapper = styled(Wrapper)<WrapperProps>`
  z-index: 0;
  position: relative;
`;

export const Gradient1 = styled.div`
  position: absolute;
  height: 3000px;
  width: 3000px;
  border-radius: 200%;
  top: -400px;
  right: 10%;
  background: linear-gradient(to right, #baf6c0, #c0ffc8, #ace3b2, #dffaca);
`;

export const LinksContainer = styled.div`
  display: flex;
  column-gap: 1rem;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      align-items: center;
    }
  `}
`;
