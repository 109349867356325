import React, { useState, useRef } from 'react';
import useOnClickOutside from '@hooks/use-on-click-outside';
import generateId from '@helpers/generate-id';
import { useLocation } from '@reach/router';
import {
  DropdownStyledLink,
  DropdownContainer,
  StyledDropDown,
  Button,
  Text,
  Links,
  StyledArrow,
  StyledChevron,
  StyledChevronContainer,
} from './dropdown.styled';

export interface DropdownItem {
  to: string;
  label: string;
}

export interface DropdownProps {
  items: DropdownItem[];
  title: string;
  to?: string;
  dropdownStyle?: 'blog' | 'navigation';
}
const dropdownIdGenerator = generateId();

const Dropdown = ({ title, items, dropdownStyle, to }: DropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <DropdownContainer ref={ref} onMouseEnter={open} onMouseLeave={close}>
      {dropdownStyle === 'blog' && (
        <Button isVisible={isOpen}>
          {title} <StyledArrow color="mindaro" />
        </Button>
      )}
      {dropdownStyle === 'navigation' && (
        <DropdownStyledLink to={to ?? ''} $isActive={location.pathname === to} $isButton={false}>
          <span>{title}</span>
          <StyledChevronContainer>
            <StyledChevron color="emerald" hideOnMobile />
          </StyledChevronContainer>
        </DropdownStyledLink>
      )}
      <StyledDropDown isVisible={isOpen}>
        {items.map((item) => {
          return (
            <Links to={item.to} key={dropdownIdGenerator.next().value}>
              <Text>{item.label}</Text>
            </Links>
          );
        })}
      </StyledDropDown>
    </DropdownContainer>
  );
};

export default Dropdown;
