import styled from 'styled-components';
import { SmallButton } from '@styles/button.styled';
import { ChromeFrame, Code } from '../terminal/terminal.styled';

export const StyledCode = styled(Code)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSmallButton = styled.a`
  ${SmallButton}
  margin: 0;
`;

export const CommandFrame = styled(ChromeFrame)`
  ${({ theme }) => `
    width: 65%;
    margin: 0 auto;
    padding: 0.25rem 0;
    overflow: visible;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      width: 75%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 65%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
    }
  `}
`;
