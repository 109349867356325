import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => `
    padding: 1.5rem 0 0;
    width: 100%;
    border-top: 1px solid ${theme.colors.emerald};

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 0;
      border-top: none;
      width: fit-content;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      padding-top: 1rem;
    }
  `}
`;

export const Title = styled.p`
  font-size: 0.75rem;
  line-height: 0.87rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--violet);
`;

export const Media = styled.div`
  margin: 0.5rem 0 0;
  display: flex;
`;

export const MediaIcon = styled.div`
  ${({ theme }) => `
    position: relative;
    margin: 0 0.75rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    background-color: ${theme.colors.hintOfGreen};
    fill: ${theme.colors.emerald};
    border-radius: 100%;
    cursor: pointer;

    path {
      fill: ${theme.colors.emerald};
    }
  `}
`;

export const MediaShareIcon = styled(MediaIcon)`
  ${({ theme }) => `
    fill: ${theme.colors.emerald};
    margin: 0;

    path {
      stroke: ${theme.colors.emerald};
    }
  `}
`;
