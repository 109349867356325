import styled, { css } from 'styled-components';
import Text from '@components/text';
import { MediumButton } from '@styles/button.styled';
import Arrow from '@components/svg-icons/arrow';
import { generateScales } from '@helpers/generate-media-queries';
import UlMarker from '@images/ul-marker.svg';

export const StyledButton = styled.a`
  ${MediumButton}
  ${generateScales('font-size', '1rem', '0.875rem')}
  width: auto;
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.75rem;
    ${generateScales('margin-top', '2.5rem', '4rem')}

    @media (max-width: ${theme.breakpoints.xl}) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;

export const StyledArrow = styled(Arrow)`
  margin-left: 0.625rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60rem;
  padding: 0 1rem;
`;

interface ImageContainerProps {
  objectFit?: 'contain' | 'cover';
}

export const ImageContainer = styled.div<ImageContainerProps>`
  ${({ theme, objectFit }) => `

  margin-top: 3.5rem;
  width: 100%;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;

  height: 40rem;
  @media (max-width: ${theme.breakpoints.lg}) {
    height: 25rem;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    height: 21rem;
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    height: unset;
  }

  div,
  img {
    object-fit: ${objectFit ?? 'contain'};
    width: 100%;
    height: 100%;
  }
  `}
`;

export const StyledExternalLink = styled.a`
  ${({ theme }) => `
    padding: 0.5rem;
    margin: 0 0.5rem;
    text-decoration: none;
    color: ${theme.colors.bottleGreen};
    font-family: ${theme.fontFamily.text};
    font-size: ${theme.lineHeights.xs};
    line-height: ${theme.lineHeights.xs};

    :hover {
      text-decoration: underline;
    }

    :last-child {
      padding-right: 0;
      margin-right: 0;
    }
  `}
`;

export const MethodsContainer = styled.div`
  display: flex;
  width: 100%;
  align-self: center;
  margin: 0 auto;
  margin-top: 3.5rem;

  ${({ theme }) => `
    padding-bottom: 8rem;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding-bottom: 6rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding-bottom: 5rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding-bottom: 4rem;
    }
  `}
`;

export const PostContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 64rem;
  align-self: center;
  margin: 0 auto;
  margin-top: 3.5rem;

  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      align-items: center;
    }

    padding-bottom: 8rem;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding-bottom: 6rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding-bottom: 5rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding-bottom: 4rem;
      margin-top: 2rem;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column-reverse;
    }
  `}
`;

export const MetaContainer = styled.div`
  margin: 0 4rem 0 0;

  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.lg}) {
      margin: 0;
      padding: 0;
      ${generateScales('margin-top', '2rem', '4rem')}
      ${generateScales('padding-top', '1rem', '4rem')}
      width: 100%;
      display: flex;
      border-top: 1px solid ${theme.colors.jungleGreen};
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      border-top: none;
      border-bottom: 1px solid ${theme.colors.jungleGreen};
      margin-top: 0;
      ${generateScales('padding-bottom', '1rem', '4rem')}
    }
  `}
`;

export const MethodMetaContainer = styled.div`
  margin: 0 0 0 4rem;

  ${({ theme }) => `
    box-shadow: ${theme.shadows.small};
    border-radius: ${theme.borderRadius.small};
    padding: 2rem 1.5rem;
    @media (max-width: ${theme.breakpoints.lg}) {
      margin: 0;
      padding: 0;
      ${generateScales('margin-top', '2.5rem', '4rem')}
      ${generateScales('padding-top', '2.5rem', '4rem')}
      width: 100%;
      display: flex;
      border-top: 1px solid ${theme.colors.jungleGreen};
    }
  `}
`;

export const MetaInfo = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 17rem;
    height: max-content;
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop)}
    line-height: 1;
    color: ${theme.colors.jungleGreen};

    @media (max-width: ${theme.breakpoints.lg}) {
      width: 100%;
      flex-direction: row;
      margin-bottom: 2rem;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      margin-bottom: 0;
    }
  `}
`;

export const Categories = styled.div`
  margin: 0;
`;

export const TopMeta = styled.div`
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.lg}) {
      margin-right: 2rem;
    }
  `}
`;

export const SubMeta = styled.div`
  ${({ theme }) => `
    display: flex;
    padding: 0.5rem 0 1.5rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 0;
    }
  `}
`;

export const TextSubMeta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AuthorPhotoContainer = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  overflow: hidden;

  div {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  + ${TextSubMeta} {
    margin: 0 0 0 0.75rem;
  }
`;

export const AuthorName = styled.div`
  ${({ theme }) => `
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop)}
    line-height: 1;
    color: ${theme.colors.jungleGreen};
  `}
`;

export const PrefixAuthorName = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.jungleGreen};
  `}
`;

export const Date = styled.div`
  ${({ theme }) => `
    margin-top: 0.25rem;
    color: ${theme.colors.jungleGreen};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop)}
    line-height: 1;
  `}
`;

export const PostBody = styled.div`
  flex: 1 auto;
`;

export const TitleContainer = styled.div`
  width: 100%;
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.emerald};
  `}
`;

export const ResearcherName = styled.div`
  ${({ theme }) => `
    ${generateScales('font-size', '0.8375rem', '1rem')}
    line-height: 1;
    color: ${theme.colors.bottleGreen};

    a, a:visited {
      color: ${theme.colors.bottleGreen};

      &:hover {
        text-decoration: none;
      }
    }
  `}
`;

export const CreditContainer = styled.div`
  width: 100%;
  margin: 2rem 0 0;

  ${({ theme }) => `
    color: ${theme.colors.bottleGreen};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop)}
    line-height: ${theme.lineHeights.lg};

    a, a:visited {
      color: ${theme.colors.jungleGreen};

      &:hover {
        text-decoration: none;
      }
    }
  `}
`;

export const CreditTitle = styled.h3`
  ${({ theme }) => `
    ${generateScales('font-size', theme.fontSize.large.mobile, theme.fontSize.large.mobile)};
    line-height: ${theme.lineHeights.sm};
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: 0.5rem;
  `}
`;

export const Title = styled(Text)`
  ${({ theme }) => `
    ${generateScales('font-size', theme.fontSize.heading3.mobile, theme.fontSize.heading4.desktop)};
    line-height: ${theme.lineHeights.sm};
  `}
`;

export const Description = styled(Text)`
  ${({ theme }) => `
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-weight: 400;
    margin-bottom: 3.5rem;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin: 2rem 0;
      margin-bottom: 2rem;
    }
  `}
`;

export const Container = styled.div`
  margin: 3.5rem 0 0;
`;

export const RichTextContainer = styled.div`
  ${({ theme }) => `
    margin: 3.5rem 0 0;
    font-family: ${theme.fontFamily.display};
    font-size: ${theme.fontSize.body.desktop};
    color: ${theme.colors.bottleGreen};
    line-height: 1.6875rem;

    p + p {
      margin-top: 1.5rem;
    }

    em {
      font-style: italic;
    }

    b, strong {
      font-weight: bold;
    }

    u {
      text-decoration: underline;
    }

    a, a:visited {
      color: ${theme.colors.jungleGreen};
    }

    h2, h3, h4, h5, h6 {
      font-family: ${theme.fontFamily.display};
      color: ${theme.colors.bottleGreen};
      margin: 1rem 0 1.5rem;
      line-height: ${theme.lineHeights.sm};
      font-weight: bold;
    }

    h2 {
      font-size: ${theme.fontSize.subheading.desktop};
    }

    h3 {
      font-size: ${theme.fontSize.large.desktop};
    }

    h4 {
      font-size: ${theme.fontSize.body.desktop};
    }

    h5 {
      font-size: ${theme.fontSize.heading5.desktop};

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        font-size: ${theme.fontSize.heading5.mobile};
      }
    }

    h6 {
      font-size: ${theme.fontSize.heading6.desktop};

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        font-size: ${theme.fontSize.heading6.mobile};
      }
    }

    ul, ol {
      margin: 2rem 0 2rem 2rem;
    }
    ul {
      li {
        margin-top: 1rem;
        font-size: ${theme.fontSize.subheading.desktop};
        list-style-image: url(${UlMarker});

        // ::before {
        //   content: url(${UlMarker});
        //   margin-left: 0rem;
        //   margin-right: 0.75rem
        // }

        @media only screen and (max-width: ${theme.breakpoints.md}) {
          font-size: ${theme.fontSize.subheading.mobile};
        }  
      }
    }

    ol {
      li {
        margin-top: 1rem;
        list-style-type: decimal;
      }
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      margin: 2rem 0;
    }
  `}
`;

export const LegalTextContainer = styled.div`
  ${({ theme }) => `
    margin: 3.5rem 0 0;
    font-family: ${theme.fontFamily.display};
    color: ${theme.colors.bottleGreen};
    line-height: 1.6875rem;

    strong {
      font-weight: 700;
    }

    em {
      font-style: italic;
    }

    p + p {
      margin-top: 1.5rem;
    }

    u {
      text-decoration: underline;
    }

    a, a:visited {
      color: ${theme.colors.jungleGreen};
    }

    font-size: 1rem;
    h1, h2 {
      font-size: 1.25rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      font-size: 1rem;
      h1, h2 {
        font-size: 1.25rem;
      }
    }

    h1 {
      text-align: center;
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: ${theme.fontFamily.display};
      color: ${theme.colors.bottleGreen};
      margin: 1rem 0 1.5rem;
      line-height: ${theme.lineHeights.sm};
    }

    h3, h4, h5, h6 {
      font-size: ${theme.fontSize.body.desktop};
    }

    ul {
      list-style: disc;
    }

    ul, li {
      margin: revert;
      padding: revert;
    }

    ol[type="a"] {
      list-style: lower-alpha;
    }

    ol:not([type="a"]) {
      counter-reset: item;

      & > li {
        list-style: auto;
        counter-increment: item;

        & > ol:not([type="a"]) > li {
          display: block;

          &:before {
            position: absolute;
            top: 0;
            left: -3rem;
            content: counters(item, ".") ".";
          }
        }
      }
    }

    ol li {
      margin-left: 1.5rem;
      position: relative;
    }
  `}
`;

export const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;
