import styled from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledDescription = styled.span<{
  textColor: keyof typeof defaultTheme.colors;
  textSize: keyof typeof defaultTheme.fontSize;
}>`
  ${({ theme, textSize, textColor = 'bottleGreen' }) => {
    return `
      font-family: ${theme.fontFamily.display};
      font-size: ${textSize};
      line-height: ${theme.lineHeights.base};  
      color: ${theme.colors[textColor]};

      ${generateScales(
        'font-size',
        theme.fontSize[textSize].mobile,
        theme.fontSize[textSize].desktop
      )}
    `;
  }}
`;
