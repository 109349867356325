import React from 'react';
import PostsList, { PostsListProps } from '@components/posts-list/relative-posts-list';
import { Container, StyleTitle } from './relative-posts.styled';

interface RelativePostsProps {
  edges: PostsListProps['edges'];
  title: string;
}

const RelativePosts = ({ edges, title }: RelativePostsProps): JSX.Element => (
  <Container>
    {title && <StyleTitle type="title" tag="h2" textSize="heading4" stringText={title} />}
    <PostsList edges={edges} />
  </Container>
);

export default RelativePosts;
