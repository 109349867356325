import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledDescription } from './description.styled';

const Description = ({
  textColor = 'bottleGreen',
  stringText = undefined,
  richText,
  textSize = 'body',
  className,
}: TextComponentProps): JSX.Element => (
  <StyledDescription textSize={textSize} textColor={textColor} className={className}>
    {richText && <PrismicRichText field={richText} />}
    {stringText}
  </StyledDescription>
);

export default Description;
