import styled from 'styled-components';
import Text from '@components/text';

export const Container = styled.div`
  width: 100%;
  max-width: 45rem;
  margin: 0 auto;
`;

export const StyledTitle = styled(Text)`
  text-align: center;
`;
