import styled, { css } from 'styled-components';
import Text from '@components/text';
import Image from '@components/image';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Eyebrow = styled(Text)`
  margin-bottom: 1.5rem;
`;

export const WrapContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 3.5rem;
    width: 100%;
    display: grid;
    justify-content: center;
    column-gap: 4rem;
    row-gap: 3rem;
    flex-wrap: wrap;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 3rem;
    justify-self: center;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      margin-top: 3rem;
      grid-column-gap: 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2rem;
      row-gap: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media only screen and (max-width: ${theme.breakpoints.xs}) {
      justify-content: center;
      row-gap: 2.5rem;
    }
  `}
`;

export const StyledImage = styled(Image)`
  max-height: 100%;
  max-width: 100%;
  height: unset;
  width: unset;
  border-radius: 0.5rem;
`;

export const StyledInfoContainer = styled.div`
  margin-top: 1rem;
`;

export const StyledAdvisorName = styled(Text)`
  font-weight: 600;
  display: block;
  text-align: center;
`;

export const StyledAdvisor = styled.div`
  ${({ theme }) => css`
    text-align: center;
    width: 100%;
    margin: 0 auto;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 12rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: 15rem;
    }

    // @media only screen and (max-width: ${theme.breakpoints.xs}) {
    //   justify-content: center;
    //   row-gap: 2.5rem;
    // }
  `}
`;
