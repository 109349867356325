import styled from 'styled-components';
import Wrapper from '@components/wrapper.styled';

export const GreenhouseEmbed = styled.div<{ $isFullpage: boolean }>`
  ${({ theme, $isFullpage }) => `
    width: 100%;
    max-height: ${$isFullpage ? '100%' : '32rem'};
    overflow: ${$isFullpage ? 'hidden' : 'auto'};
    color: ${theme.colors.bottleGreen};
    ::-webkit-scrollbar {
      width: 0.75rem;
    }

    ::-webkit-scrollbar-track {
      background: ${theme.colors.emerald};
      border-radius: ${theme.borderRadius.small};
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.mindaro};
      border-radius: ${theme.borderRadius.small};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.hintOfGreen};
    }
  `}
`;

export const StyledWrapper = styled(Wrapper)`
  text-align: center;
`;
