import styled from 'styled-components';
import Text from '@components/text';
import ExternalLink from '@components/external-link/external-link.styled';
import { LargeCard } from '@styles/cards.styled';

export const Card = styled.div`
  ${LargeCard}

  ${({ theme }) => `
    position: relative;
    z-index: ${theme.zIndex.layeredContent};
    display: flex;
    justify-content: center;
    margin: 0 auto;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 0 ${theme.padding.cards.md};

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 0;
    }
  `}
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  width: 100%;
  margin-top: 2rem;

  div {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const StyledExternalLink = styled(ExternalLink)`
  margin-top: 2.5rem;
`;

export const StyledText = styled(Text)`
  + ${StyledExternalLink} {
    margin-top: 0.25rem;
  }
`;
