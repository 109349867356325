import styled from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';

export const Container = styled.div`
  ${({ theme }) => `
    margin: 5rem auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 2rem;
    min-height: 4rem;
    max-width: 34.375rem;
    width: max-content;
    background: ${theme.colors.hintOfGreen};
    border-radius: ${theme.borderRadius.large};
  `}
`;

export const NavBlock = styled.div`
  margin: 0 4.5rem;
  display: flex;
  max-width: 19.125rem;
  width: max-content;
`;

export const NavLink = styled.a<{ $isDisabled?: boolean }>`
  ${({ theme, $isDisabled }) => `
    font-family: ${theme.fontFamily.display};
    ${generateScales('font-size', '0.875rem', '1rem')}
    line-height: ${theme.lineHeights.base};
    color: ${theme.colors.bottleGreen};
    text-decoration: none;
    margin: 0;
    ${
      $isDisabled
        ? `
    pointer-events: none;
    color: ${theme.colors.mountainMeadow};
    `
        : ``
    }
  `}
`;

export const NumberLink = styled(NavLink)`
  &:not(:last-child) {
    margin: 0 2rem 0 0;
  }
`;
