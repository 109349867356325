import React from 'react';

interface RedditIconProps {
  fill?: string;
}

const RedditIcon = ({ fill }: RedditIconProps): JSX.Element => (
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8012 7.63287C16.8012 6.61417 15.9744 5.7874 14.9557 5.7874C14.4537 5.7874 14.0108 5.97933 13.686 6.30413C12.4311 5.40354 10.689 4.81299 8.76967 4.73917L9.61121 0.797244L12.3425 1.37303C12.372 2.06693 12.9478 2.62795 13.6565 2.62795C14.3799 2.62795 14.9705 2.0374 14.9705 1.31398C14.9705 0.590552 14.3799 0 13.6565 0C13.1397 0 12.6968 0.295276 12.4901 0.738189L9.43404 0.0885836C9.34546 0.0738198 9.25688 0.0885829 9.18306 0.132874C9.10924 0.177166 9.06495 0.250985 9.03542 0.339567L8.1053 4.73917C6.14172 4.79823 4.38484 5.37401 3.11515 6.30413C2.79035 5.99409 2.33267 5.7874 1.84547 5.7874C0.826771 5.7874 0 6.61417 0 7.63287C0 8.38583 0.442913 9.02067 1.09252 9.31594C1.06299 9.49311 1.04823 9.68504 1.04823 9.87697C1.04823 12.7116 4.34055 15 8.41534 15C12.4901 15 15.7825 12.7116 15.7825 9.87697C15.7825 9.68504 15.7677 9.50787 15.7382 9.33071C16.3435 9.03543 16.8012 8.38583 16.8012 7.63287ZM4.17814 8.94685C4.17814 8.22343 4.7687 7.63287 5.49212 7.63287C6.21554 7.63287 6.80609 8.22343 6.80609 8.94685C6.80609 9.67028 6.21554 10.2608 5.49212 10.2608C4.7687 10.2608 4.17814 9.67028 4.17814 8.94685ZM11.5157 12.4163C10.6151 13.3169 8.90255 13.376 8.40058 13.376C7.89861 13.376 6.17125 13.3022 5.28543 12.4163C5.15255 12.2835 5.15255 12.062 5.28543 11.9291C5.4183 11.7963 5.63976 11.7963 5.77263 11.9291C6.33365 12.4902 7.54428 12.6968 8.41534 12.6968C9.28641 12.6968 10.4823 12.4902 11.0581 11.9291C11.1909 11.7963 11.4124 11.7963 11.5453 11.9291C11.6486 12.0768 11.6486 12.2835 11.5157 12.4163ZM11.2795 10.2608C10.5561 10.2608 9.96554 9.67028 9.96554 8.94685C9.96554 8.22343 10.5561 7.63287 11.2795 7.63287C12.0029 7.63287 12.5935 8.22343 12.5935 8.94685C12.5935 9.67028 12.0029 10.2608 11.2795 10.2608Z"
      fill={fill}
    />
  </svg>
);

export default RedditIcon;
