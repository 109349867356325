import TypingAnimation from '@components/animations/TypingAnimation';
import { PrismicRichText } from '@prismicio/react';
import { RichTextField } from '@prismicio/types';
import React, { useMemo, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import {
  ChromeFrame,
  ChromeControls,
  CloseControl,
  MinimizeControl,
  MaximizeControl,
  ChromeBody,
  LinesWrapper,
  Line,
  Code,
  CodeResponse,
  SectionTitle,
  CodeSection,
  Hidden,
  ResponseContainer,
} from './terminal.styled';

interface TerminalProps {
  code?: RichTextField;
  terminal?: RichTextField;
  response?: RichTextField;
}

const Terminal = ({ code, terminal, response }: TerminalProps): JSX.Element => {
  const [showResponse, setShowResponse] = useState(false);

  const lines: JSX.Element[] = [];
  for (let i = 0; i < 10; i += 1) {
    lines.push(<Line key={`line-${i}`}>{i + 1}</Line>);
  }

  const fadeInResponse = useMemo(
    () => (
      <Fade cascade triggerOnce style={{ opacity: 0 }}>
        {response?.map((t) => (
          <PrismicRichText field={[t]} />
        ))}
      </Fade>
    ),
    [response]
  );

  return (
    <ChromeFrame>
      <ChromeControls>
        <CloseControl />
        <MinimizeControl />
        <MaximizeControl />
      </ChromeControls>
      <ChromeBody>
        <LinesWrapper>{lines}</LinesWrapper>
        <Code>
          <SectionTitle>demo.py</SectionTitle>
          <PrismicRichText field={code} />
        </Code>
      </ChromeBody>
      {terminal && (
        <CodeSection>
          <SectionTitle>Terminal</SectionTitle>
          <TypingAnimation triggerOnce text={terminal} done={() => setShowResponse(true)} />
        </CodeSection>
      )}
      {response && (
        <CodeResponse>
          <SectionTitle>Response</SectionTitle>
          <ResponseContainer $hidden={!showResponse}>
            {showResponse ? fadeInResponse : <PrismicRichText field={response} />}
          </ResponseContainer>
        </CodeResponse>
      )}
    </ChromeFrame>
  );
};

export default Terminal;
