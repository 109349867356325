import styled from 'styled-components';

export const ImageContainer = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 29vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => `
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;
