import styled from 'styled-components';
import FONTS from '@constants/fonts';
import getItemWidthForGrid from '@helpers/get-item-width-for-grid';

export const ImageContainer = styled.div<{ itemsPerRow?: number }>`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  border-radius: 1rem;
  overflow: hidden;
  width: ${({ itemsPerRow = 3 }) => getItemWidthForGrid(itemsPerRow)};

  div {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled.h3`
  font-family: ${FONTS.KlarheitKurrent};
  margin: 2rem 0 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
`;
