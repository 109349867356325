import { css } from 'styled-components';
import { StyledSVG } from '@components/svg-icons/svg-icon.styled';
import { generateScales } from '@helpers/generate-media-queries';
import { defaultTheme } from '@styles/theme.styled';
import getContrastYIQ from '@helpers/get-color-contrast';

interface ButtonProps {
  $primaryColor?: keyof typeof defaultTheme.colors;
  $hoverColor?: keyof typeof defaultTheme.colors;
  size?: 'sm' | 'md' | 'lg';
}

const LgButton = css`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return css`
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('height', mobile.height, desktop.height)}
    `;
  }}
`;

const MdButton = css`
  ${({ theme }) => {
    const { mobile, desktop } = theme.buttonSize.md;

    return css`
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('height', mobile.height, desktop.height)}
    `;
  }}
`;

const SmButton = css`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.sm;

    return css`
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('height', mobile.height, desktop.height)}
    `;
  }}
`;

export const Button = css<ButtonProps>`
  ${({ theme, $primaryColor = 'mindaro', $hoverColor = 'jungleGreen', size = 'lg' }) => {
    const primaryVal = theme.colors[$primaryColor] ?? $primaryColor;
    const hoverVal = theme.colors[$hoverColor] ?? $hoverColor;

    return css`
      text-decoration: none;
      font-family: ${theme.fontFamily.display};
      font-weight: 500;
      cursor: pointer;
      display: flex;
      width: fit-content;
      justify-content: center;
      align-items: center;
      border-width: ${theme.borderWidths.icons};
      border-style: solid;
      border-color: ${primaryVal};
      background: ${primaryVal};
      color: ${theme.colors[getContrastYIQ(primaryVal)]};

      :hover {
        color: ${theme.colors[getContrastYIQ(hoverVal)]};
        background-color: ${hoverVal};
        border-color: ${hoverVal};
      }

      ${StyledSVG} {
        stroke: ${theme.colors[getContrastYIQ(primaryVal)]};
      }

      ${size === 'lg' && LgButton}
      ${size === 'md' && MdButton}
      ${size === 'sm' && SmButton}
    `;
  }}
`;

export const LargeButton = css`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return css`
      ${Button}
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('height', mobile.height, desktop.height)}
    `;
  }}
`;

export const MediumButton = css`
  ${({ theme }) => {
    const { mobile, desktop } = theme.buttonSize.md;

    return css`
      ${Button}
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('height', mobile.height, desktop.height)}
    `;
  }}
`;

export const SmallButton = css`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.sm;

    return css`
      ${Button}
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('height', mobile.height, desktop.height)}
    `;
  }}
`;
