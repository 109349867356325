import styled, { css } from 'styled-components';

export const StyledGrid = styled.div<{ itemsPerRow?: number; itemWith: number }>`
  ${({ theme, itemsPerRow = 3 }) => css`
    justify-content: center;
    display: grid;
    column-gap: 2rem;
    row-gap: 5rem;
    grid-template-columns: repeat(${itemsPerRow}, 1fr);

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: 1fr 1fr;
      row-gap: 4rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: ${theme.breakpoints.xs}) {
      grid-gap: 2rem;
      grid-template-columns: 1fr;
    }
  `}
`;
