import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { CookieConsentWrapper, StyledButton } from './cookie-consent.styled';

const CookiePrompt = () => {
  return (
    <CookieConsentWrapper>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        containerClasses="cookie-consent-container"
        contentClasses="cookie-consent-content"
        buttonWrapperClasses="cookie-consent-btn-wrapper"
        declineButtonClasses="cookie-consent-decline-btn"
        disableStyles
        flipButtons
        enableDeclineButton
        ButtonComponent={StyledButton}
        disableButtonStyles
        // buttonStyle={{ color: defaultTheme.colors.bottleGreen, fontSize: '13px' }}
      >
        We use cookies and similar technologies to record your visits, as well as to analyze
        traffic. By clicking &ldquo;Accept&rdquo;, you consent to the use of cookies unless you have
        disabled them.
      </CookieConsent>
    </CookieConsentWrapper>
  );
};

export default CookiePrompt;
