import { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';

export const BackgroundDiagonal = css<{ backgroundColor: keyof typeof defaultTheme.colors }>`
  ${({ theme, backgroundColor = 'transparent' }) => `
    position: relative;
    padding: 8rem 0;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 6rem 0;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 5rem 0;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 4rem 0;
    }

    :before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: ${theme.colors[backgroundColor]}; 	
      transform: skewY(-4deg);
      z-index: ${theme.zIndex.surface};

      // make diagonal less extreme at smaller breakpoints

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        transform: skewY(-4deg);
      }
    }
  `}
`;
