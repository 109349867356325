/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import PageTemplate from './src/templates/page';
import PostTemplate from './src/templates/post';
import FullPageVideoTemplate from './src/templates/full-page-video';
import { linkResolver } from './link-resolver'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
          post: PostTemplate,
          full_page_video: FullPageVideoTemplate,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)