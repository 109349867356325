import styled from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  color: unset;
  text-decoration: none;
  width: 100%;
`;

export const Container = styled.div`
  ${({ theme }) => `
    ${generateScales('padding-top', '3em', '5rem')}
    width: 100%;
    display: flex;
    align-items: center;
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: center;
    }
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
    border-radius: ${theme.borderRadius.small};
    overflow: hidden;
  `}
`;

export const DescriptionContainer = styled.div`
  ${({ theme }) => `
    width: 100%;
    max-width: 32rem;
    height: 100%;
    margin-left: 2rem;
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin-top: 1.5rem;
      margin-left: 0;
    }
  `}
`;

export const Title = styled(Text)`
  ${({ theme }) => `
    ${generateScales('font-size', '1.0625rem', '1.5rem')}
    line-height: ${theme.lineHeights.sm};
    color: ${theme.colors.bottleGreen};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => `
    margin: 1rem 0 0;
    color: ${theme.colors.bottleGreen};
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop)}
    font-family: ${theme.fontFamily.display};
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.base};
  `}
`;

export const DescriptionCategory = styled.p`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop)}
    text-transform: uppercase;
    line-height: ${theme.lineHeights.xs};
    color: ${theme.colors.jungleGreen};
    margin-bottom: 1rem;
  `}
`;
