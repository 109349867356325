import React from 'react';
import { RichTextField } from '@prismicio/types';
import CallToAction from '@components/call-to-action';
import PageHero from '@components/page-hero';
import HeroWithSubarticles from '@components/hero-with-subarticles';
import Image, { ImageProps } from '@components/image';
import Resources from '@components/resources';
import Showcase from '@components/showcase';
import ProductOverview from '@components/product-overview';
import GreenhouseJobBoard from '@components/greenhouse-job-board';
import PostsListContainer from '@components/posts-list-container';
import Carousel from '@components/carousel';
import Investors from '@components/investors';
import FollowTheLink from '@components/follow-the-link';
import BoardMembers from '@components/board-members';
import FeatureGrid from '@components/feature-grid';
import RichText from '@components/rich-text';
import Team from '@components/team';
import HowItWorks from '@components/how-it-works';
import { defaultTheme } from '@styles/theme.styled';
import CodeCarousel from '@components/code-carousel';
import Markdown from '@components/markdown';
import VideoFeature from '@components/video-feature';
import CodeDemo from '@components/code-demo';
import Advisors from '@components/advisors';
import { Item } from './items-props.type';
import { ModuleContainer, ImageContainer } from './module.shared.styled';

export interface PrimaryProps {
  id?: {
    text: string;
  };
  eyebrow?: {
    richText: RichTextField;
    text: string;
  };
  title?: {
    richText: RichTextField;
    text: string;
  };
  description?: {
    richText: RichTextField;
  };
  subtitle?: {
    richText: RichTextField;
    text: string;
  };
  subheading?: {
    richText: RichTextField;
    text: string;
  };
  link: {
    url: string;
    target: string;
  };
  link_label: string;
  link_url: {
    url: string;
    target: string;
  };
  prompt?: string;
  image: ImageProps;
  left_image: ImageProps;
  right_image: ImageProps;
  sign_up_form?: boolean;
  post_title?: {
    text: string;
  };
  input_placeholder?: string;
  button_style?: string;
  button_label: string;
  button_link: {
    url: string;
    target: string;
  };
  position?: string;
  full_page: boolean;
  align?: string;
  left_image_position?: string;
  right_image_position?: string;
  token: string;
  items_per_row?: string;
  color?: keyof typeof defaultTheme.colors;
  background_color?: keyof typeof defaultTheme.colors;
  background_image?: ImageProps;
  background_position?: string;
  rich_text?: {
    richText: RichTextField;
  };
  code?: {
    richText: RichTextField;
  };
  response?: {
    richText: RichTextField;
  };
  image_layout: 'inline' | 'masked';
  markdown: {
    richText: RichTextField;
  };
  video?: {
    url: string;
  };
  video_poster?: {
    url: string;
  };
  hide?: boolean;
  start_full_page_background?: boolean;
  terminal?: {
    richText: RichTextField;
  };
  center_title?: boolean;
  center_all?: boolean;
  secondary_link_label?: string;
  secondary_link_color?: string;
  secondary_link_hover_color?: string;
  secondary_link_url?: {
    url: string;
    target: string;
  };
  gradient_background: boolean;
  home: boolean;
}

export interface ModuleProps {
  slice_type: string;
  slice_label: string;
  primary: PrimaryProps;
  items: Item[];
}

const renderModule = ({
  slice_type,
  slice_label,
  primary,
  items,
}: ModuleProps): JSX.Element | null => {
  const itemsPerRow = (slice_label && +slice_label.slice(0, 1)) || 3;
  if (primary?.hide) {
    return null;
  }
  switch (slice_type) {
    case 'page_hero':
      return (
        <PageHero
          title={primary.title?.text}
          description={primary.description?.richText}
          subheading={primary.subheading?.richText}
          image={primary.image}
          imageLayout={primary.image_layout}
          backgroundColor={primary.background_color}
          linkLabel={primary.link_label}
          linkUrl={primary.link_url}
          video={primary.video}
          videoPoster={primary.video_poster}
          centerAll={primary.center_all}
          centerTitle={primary.center_title}
          secondaryLinkLabel={primary.secondary_link_label}
          secondaryLinkUrl={primary.secondary_link_url}
          secondaryLinkColor={primary.secondary_link_color}
          secondaryLinkHoverColor={primary.secondary_link_hover_color}
          gradientBackground={primary.gradient_background}
          home={primary.home}
        />
      );
    case 'hero_with_subarticles':
      return (
        <HeroWithSubarticles
          moduleTitle={primary.title?.text}
          subheading={primary.subheading?.richText}
          items={items}
        />
      );
    case 'feature_grid':
      return (
        <FeatureGrid
          start_full_page_background={primary?.start_full_page_background}
          id={primary.id?.text}
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          link={primary.link}
          linkLabel={primary.link_label}
          backgroundColor={primary.background_color}
          backgroundImage={primary.background_image}
          backgroundPosition={primary.background_position}
          itemsPerRow={primary.items_per_row}
          items={items}
        />
      );
    case 'how_it_works':
      return (
        <HowItWorks
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          richText={primary.rich_text?.richText}
          image={primary.image}
        />
      );
    case 'product_overview':
      return (
        <ProductOverview
          label={slice_label}
          title={primary.title?.text}
          description={primary.description?.richText}
          items={items}
        />
      );
    case 'showcase':
      return (
        <Showcase
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          description={primary.description?.richText}
          linkUrl={primary.link.url}
          linkLabel={primary.link_label}
        />
      );
    case 'call_to_action':
      return (
        <CallToAction
          title={primary.title?.text}
          link={primary.link}
          linkLabel={primary.link_label}
          backgroundColor={primary.background_color}
          backgroundImage={primary.background_image}
        />
      );
    case 'image': {
      if (!primary.image?.gatsbyImageData) {
        return <></>;
      }

      return (
        <ModuleContainer>
          <ImageContainer>
            <Image gatsbyImageData={primary.image?.gatsbyImageData} url={primary.image?.url} />
          </ImageContainer>
        </ModuleContainer>
      );
    }
    case 'community':
      return (
        <Resources
          moduleTitle={primary.title?.text}
          description={primary.description?.richText}
          items={items}
        />
      );
    case 'posts_list':
      return (
        <ModuleContainer>
          <PostsListContainer primary={primary} />
        </ModuleContainer>
      );
    case 'carousel':
      return <Carousel items={items} />;
    case 'investors':
      return (
        <Investors
          id={primary.id?.text}
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          items={items}
          description={primary.description}
        />
      );
    case 'angel_investors':
      return (
        <BoardMembers
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          items={items}
          itemsPerRow={itemsPerRow}
        />
      );
    case 'advisors':
      return (
        <Advisors
          id={primary.id?.text}
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          items={items}
          description={primary.description}
        />
      );
    case 'follow_the_link':
      return (
        <FollowTheLink
          moduleTitle={primary.title?.text}
          description={primary.description?.richText}
          buttonLabel={primary.button_label}
          buttonUrl={primary.button_link?.url}
          image={primary.image}
        />
      );
    case 'code_demo':
      return (
        <CodeDemo
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          description={primary.description}
          code={primary.code}
          terminal={primary.terminal}
          response={primary.response}
          backgroundColor={primary.background_color}
        />
      );
    case 'code_demo_carousel':
      return (
        <CodeCarousel
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          prompt={primary.prompt}
          items={items}
        />
      );
    case 'greenhouse_job_board':
      return (
        <GreenhouseJobBoard
          id={primary.id?.text}
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          token={primary.token}
          full_page={primary.full_page}
          backgroundColor={primary.background_color}
        />
      );
    case 'team':
      return (
        <Team
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          items={items}
          id={primary.id?.text}
        />
      );
    case 'video_feature':
      return (
        <VideoFeature
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          description={primary.description}
          video={primary.video}
          videoPoster={primary.video_poster}
          backgroundColor={primary.background_color ?? 'white'}
        />
      );
    case 'rich_text':
      return <RichText title={primary.title?.text} description={primary.description?.richText} />;
    case 'markdown':
      return <Markdown markdown={primary.markdown?.richText} />;
    default:
      return null;
  }
};

export default renderModule;
