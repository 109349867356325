import styled, { css } from 'styled-components';
import { Button } from '@styles/button.styled';
import Wrapper from '@components/wrapper.styled';
import { generateScales } from '@helpers/generate-media-queries';

const StyledWrapper = styled(Wrapper)`
  ${({ theme }) => css`
    position: relative;
    z-index: ${theme.zIndex.aboveLayers}};
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 2rem;
    min-height: 4rem;
    width: 100%;
    background-color: ${theme.colors.bottleGreen};
    border-radius: 2rem;
    z-index: ${theme.zIndex.layeredContent};
    position: relative;
    gap: 2rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      gap: 1.25rem;
      border-radius: 1rem;
      padding: 1rem 2rem;
    }
  `}
`;

const Categories = styled.nav`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1.5rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      gap: 1.25rem;
    }
  `}
`;

const Category = styled.a<{ $isActive?: boolean }>`
  ${({ theme, $isActive = false }) => css`
    padding: 0.5rem 0;
    font-family: ${theme.fontFamily.display};
    ${generateScales('font-size', '0.8375rem', '1rem')}
    line-height: 1;
    text-decoration: none;
    color: ${$isActive ? theme.colors.white : theme.colors.mindaro};
    pointer-events: ${$isActive ? 'none' : 'auto'};

    :hover {
      color: ${theme.colors.white};
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      :not(:nth-child(1)):not(:nth-child(2)) {
        display: none;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.xs}) {
      :not(:nth-child(1)) {
        display: none;
      }
    }
  `}
`;

const StyledForm = styled.form`
  display: flex;
  gap: 0.5rem;
`;

const StyledButton = styled.button`
  ${Button}
`;

export { StyledWrapper, Container, Categories, Category, StyledForm, StyledButton };
