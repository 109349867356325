import styled from 'styled-components';

const StyledInput = styled.input`
  ${({ theme }) => `
    border: 2px solid ${theme.colors.jungleGreen};
    background-color: ${theme.colors.white};
    color: ${theme.colors.bottleGreen};
    outline: none;
    font-family: ${theme.fontFamily.display};
    transition: all ${theme.transitions.normal};

    ::placeholder {
      color: ${theme.colors.emerald};
    }

    :focus {
      border-color: ${theme.colors.bottleGreen};
    }
  `}
`;

export default StyledInput;
