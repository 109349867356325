import React from 'react';
import Image, { ImageProps } from '@components/image';
import generateId from '@helpers/generate-id';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';
import getFormatDate from '@helpers/get-format-date';
import {
  StyledSection,
  EmptyState,
  PostsGrid,
  StyledCustomLink,
  StyledTitle,
  ImageContainer,
  Meta,
  Category,
  Description,
  NoImage,
  StyledLink,
  StyledDate,
} from './posts-list.shared.styled';

const postsListIdGenerator = generateId();

export interface PostsListProps {
  title?: string;
  posts?: PostPreviewType[];
}

export interface PostPreviewType {
  data: {
    post_title: {
      text: string;
    }[];
    post_description: {
      text: string;
    }[];
    post_preview_image: ImageProps;
  };
  uid: string;
  first_publication_date: string;
  last_publication_date: string;
  tags: string[];
}

const PostsList = ({ title, posts }: PostsListProps): JSX.Element => {
  return (
    <StyledSection backgroundColor="white">
      {title && <StyledTitle textSize="heading4" type="title" stringText={title} />}
      <Wrapper>
        {posts?.length && posts.length > 0 ? (
          <PostsGrid>
            {posts?.map(({ data, uid, tags, first_publication_date }: PostPreviewType) => (
              <div key={postsListIdGenerator.next().value}>
                <StyledCustomLink href={`/blog/${uid}`}>
                  <ImageContainer>
                    {data.post_preview_image?.url ? (
                      <Image url={data.post_preview_image.url} alt={data.post_preview_image.alt} />
                    ) : (
                      <NoImage />
                    )}
                  </ImageContainer>
                </StyledCustomLink>
                <Meta>
                  {tags?.length > 0 && (
                    <span>
                      {tags.map((tag: string) => {
                        const to = `/blog?category=${tag}`;

                        return (
                          tag && (
                            <StyledLink href={to} key={postsListIdGenerator.next().value}>
                              <Category>{tag}&nbsp;</Category>
                            </StyledLink>
                          )
                        );
                      })}
                    </span>
                  )}
                </Meta>
                {data.post_title && data.post_title[0] && (
                  <StyledCustomLink href={`/blog/${uid}`}>
                    <Text
                      type="title"
                      tag="h3"
                      textSize="heading6"
                      stringText={data.post_title[0].text}
                    />
                  </StyledCustomLink>
                )}
                {data.post_description && data.post_description[0] && (
                  <Description>{data.post_description[0].text}</Description>
                )}
                <StyledDate>{getFormatDate(first_publication_date)}</StyledDate>
              </div>
            ))}
          </PostsGrid>
        ) : (
          <EmptyState>{posts ? 'No posts found.' : undefined}</EmptyState>
        )}
      </Wrapper>
    </StyledSection>
  );
};

export default PostsList;
