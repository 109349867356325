import React from 'react';
import { defaultTheme } from '@styles/theme.styled';
import { StyledSVG } from './svg-icon.styled';

interface ButtonProps {
  color?: keyof typeof defaultTheme.colors;
  width?: number;
  height?: number;
  className?: string;
}

const Chevron = ({
  color = 'jungleGreen',
  width = 48,
  height = 48,
  className = '',
}: ButtonProps): JSX.Element => (
  <StyledSVG
    color={color}
    width={width}
    height={height}
    viewBox="12 8 4 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 16L16 12L12 8"
      stroke={color}
      strokeWidth={defaultTheme.borderWidths.icons}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </StyledSVG>
);

export default Chevron;
