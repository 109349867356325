import styled from 'styled-components';
import Text from '@components/text';

export const Container = styled.div`
  margin-top: 4.5rem;
`;

export const StyleTitle = styled(Text)`
  text-align: center;
`;
