import styled, { css } from 'styled-components';
import Grid from '@components/grid';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledGrid = styled(Grid)`
  ${({ theme }) => css`
    ${generateScales('margin-top', '2rem', '4rem')}
    row-gap: 2rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: 1fr;
    }
  `}
`;
