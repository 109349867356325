import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { RichTextField } from '@prismicio/types';
import Image, { ImageProps } from '@components/image';
import Text, { TextHead } from '@components/text';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { RichTextContainer } from '@components/paragraphs/paragraphs.shared.styled';
import { ImageContainer } from './how-it-works.styled';

interface HowItWorksProps {
  eyebrow?: string;
  title?: string;
  richText?: RichTextField;
  image?: ImageProps;
}

const HowItWorks = ({ eyebrow, title, richText, image }: HowItWorksProps): JSX.Element => {
  return (
    <Section backgroundColor="white">
      <Wrapper>
        <TextHead align="left">
          {!!eyebrow && <Text type="eyebrow" stringText={eyebrow} />}
          {!!title && <Text type="title" stringText={title} />}
          {!!richText?.length && (
            <RichTextContainer>
              <PrismicRichText field={richText} />
            </RichTextContainer>
          )}
        </TextHead>
        {!!(image?.gatsbyImageData || image?.url) && (
          <ImageContainer>
            <Image gatsbyImageData={image?.gatsbyImageData} url={image?.url} alt={image?.alt} />
          </ImageContainer>
        )}
      </Wrapper>
    </Section>
  );
};

export default HowItWorks;
