import styled, { css } from 'styled-components';

const paddingLeftRight = 1;
export const maxWidthLg = 73.125 + 2 * paddingLeftRight;

interface TextContainerProps {
  widthSize?: 'sm' | 'md' | 'lg';
  alignment?: 'left' | 'center';
}

export const Wrapper = styled.div<TextContainerProps>`
  ${({ theme, widthSize = 'lg', alignment = 'left' }) => css`
    margin: 0 auto;
    width: 100%;
    max-width: ${theme.wrappers.xxl};
    padding: 0 ${theme.spacing.wrapperGap};
    z-index: 2;
    position: relative;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      max-width: ${theme.wrappers.xl};
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: ${theme.wrappers.lg};
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: ${theme.wrappers.md};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: ${theme.wrappers.sm};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: ${theme.wrappers.xs};
    }

    ${alignment === 'center' && `justify-content: center;`}

    ${widthSize === 'md' &&
    css`
      width: 1056px;

      @media (max-width: ${theme.breakpoints.xxl}) {
        width: 1026px;
      }

      @media only screen and (max-width: ${theme.breakpoints.xl}) {
        max-width: 960px;
      }

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        max-width: 720px;
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        max-width: 540px;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        max-width: 100%;
      }
    `}
  `}
`;

export default Wrapper;
