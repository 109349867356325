import React from 'react';

interface TwitterIconProps {
  fill?: string;
}

const TwitterIcon = ({ fill }: TwitterIconProps): JSX.Element => (
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3768 0.400029C13.7603 0.834911 13.0777 1.16753 12.3552 1.38507C11.9675 0.939231 11.4522 0.623234 10.879 0.479814C10.3058 0.336394 9.7024 0.372471 9.15038 0.583164C8.59837 0.793858 8.12438 1.169 7.79251 1.65786C7.46065 2.14672 7.28694 2.72571 7.29486 3.31651V3.96033C6.16344 3.98966 5.04232 3.73873 4.03136 3.22988C3.0204 2.72103 2.15098 1.97006 1.50052 1.04384C1.50052 1.04384 -1.07474 6.83818 4.7196 9.41344C3.39368 10.3135 1.81416 10.7648 0.212891 10.7011C6.00723 13.9201 13.0892 10.7011 13.0892 3.2972C13.0886 3.11786 13.0714 2.93897 13.0377 2.76283C13.6948 2.11483 14.1585 1.29668 14.3768 0.400029Z"
      fill={fill}
    />
  </svg>
);

export default TwitterIcon;
