import React from 'react';
import { ImageProps } from '@components/image';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import Text, { TextHead } from '@components/text';
import { defaultTheme } from '@styles/theme.styled';
import getContrastYIQ from '@helpers/get-color-contrast';
import useScrollToSection from '@hooks/use-scroll-to-section';
import { RichTextField } from '@prismicio/types';
import SubtleSlideFade from '@components/animations/SubtleSlideFade';
import {
  Container,
  Eyebrow,
  WrapContainer,
  StyledImage,
  StyledInfoContainer,
  StyledAdvisorName,
  StyledAdvisor,
} from './advisors.styled';

const textColor = getContrastYIQ(defaultTheme.colors.white);

export interface AdvisorsPropsItem {
  image?: ImageProps;
  advisor_name?: string;
  advisor_title?: string;
}

export interface AdvisorsProps {
  id?: string | undefined;
  eyebrow?: string;
  title?: string;
  items?: AdvisorsPropsItem[];
  description?: {
    richText: RichTextField;
  };
}

const Advisor = ({ advisor }: { advisor: AdvisorsPropsItem }) => {
  const { image, advisor_name, advisor_title } = advisor;
  return (
    <StyledAdvisor>
      <StyledImage gatsbyImageData={image?.gatsbyImageData} url={image?.url} alt={image?.alt} />
      <StyledInfoContainer>
        <StyledAdvisorName stringText={advisor_name} textColor={textColor} />
        <Text stringText={advisor_title} textColor={textColor} />
      </StyledInfoContainer>
    </StyledAdvisor>
  );
};

const AdvisorRow = ({ advisorRows }: { advisorRows: AdvisorsPropsItem[][] }) => {
  return (
    <>
      {advisorRows.map((advisorRow) => (
        <WrapContainer>
          <SubtleSlideFade direction="bottom" cascade triggerOnce>
            {advisorRow?.map((advisor) => {
              if (advisor.image?.url === null && !advisor.advisor_name) {
                return <></>;
              }

              return <Advisor advisor={advisor} />;
            })}
          </SubtleSlideFade>
        </WrapContainer>
      ))}
    </>
  );
};

const Advisors = ({ id, eyebrow, title, description, items = [] }: AdvisorsProps): JSX.Element => {
  useScrollToSection(id);
  const areItemsProvided: boolean =
    items?.length > 0 &&
    (items[0].image?.gatsbyImageData !== null || Boolean(items[0].image?.url)) &&
    Boolean(items[0].advisor_name);

  if (!title || !areItemsProvided) {
    return <></>;
  }

  return (
    <Section backgroundColor="orinoco" id={id}>
      <Wrapper alignment="center">
        <Container>
          <TextHead>
            <Eyebrow type="eyebrow" stringText={eyebrow} />
            <Text
              tag="h3"
              textColor={textColor}
              textSize="heading3"
              type="title"
              stringText={title}
            />
            {description && (
              <Text type="subheading" richText={description.richText} textColor={textColor} />
            )}
          </TextHead>
          {areItemsProvided && <AdvisorRow advisorRows={[items]} />}
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Advisors;
