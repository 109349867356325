import { css } from 'styled-components';

const ColorVars = css`
  --transparent: transparent;
  --white: #ffffff;
  --black: #000000;
  --bottleGreen: #0a3f37;
  --orinoco: #dffaca;
  --mindaro: #c1fd74;
  --emerald: #61d16f;
  --jungleGreen: #28a862;
  --hintOfGreen: #dffde3;
  --mountainMeadow: #169b84;
  --transparentWhite: rgba(255, 255, 255, 0.8);
`;

export default ColorVars;
