import React from 'react';
import Image from '@components/image';
import { OverviewItemProps } from '@components/product-overview';
import { ImageContainer, Title } from './content-block.styled';

interface ContentBlockProps {
  image: OverviewItemProps['image'];
  title: OverviewItemProps['title'];
  itemsPerRow?: number;
}

const ContentBlock = ({ image, title, itemsPerRow = 3 }: ContentBlockProps): JSX.Element => (
  <div>
    {image && (
      <ImageContainer itemsPerRow={itemsPerRow}>
        <Image url={image?.url} gatsbyImageData={image?.gatsbyImageData} alt={image?.alt} />
      </ImageContainer>
    )}
    {title?.text && <Title>{title.text}</Title>}
  </div>
);

export default ContentBlock;
