import React from 'react';
import { useLocation } from '@reach/router';
import generateId from '@helpers/generate-id';
import PageInfoProps from '../../pages/blog';
import { Container, NavBlock, NavLink, NumberLink } from './pagination.styled';

const paginationIdGenerator = generateId();

export interface PageInfoProps {
  page: number | string;
  prev_page: null | string;
  next_page: null | string;
  total_pages: number;
}

interface PaginationProps {
  pageInfo: PageInfoProps;
}

const Pagination = ({ pageInfo }: PaginationProps): JSX.Element | null => {
  const location = useLocation();
  const activeCategory = new URLSearchParams(location.search).get('category') || '';
  const activeSearch = new URLSearchParams(location.search).get('search') || '';

  const { total_pages, next_page, prev_page, page } = pageInfo;
  const pages1to6 = Array.from({ length: total_pages > 8 ? 6 : total_pages }, (_, i) => i + 1);
  const restPages = total_pages > 8 ? [...pages1to6, '...', total_pages] : pages1to6;

  const getPage = (currentPage: number) => {
    const isAnyParam = activeSearch || activeCategory || currentPage.toString();

    return `/blog${
      isAnyParam
        ? `?${activeCategory ? `category=${activeCategory}` : ''}${
            activeSearch ? `${activeCategory ? '&' : ''}search=${activeSearch}` : ''
          }${currentPage ? `${activeCategory || activeSearch ? '&' : ''}page=${currentPage}` : ''}`
        : ''
    }`;
  };

  const getLinkPage = (item: number | string) => (typeof item === 'number' && getPage(item)) || '';

  return (
    <Container>
      <NavLink href={getPage(+page - 1)} $isDisabled={!prev_page}>
        ←
      </NavLink>
      <NavBlock>
        {restPages.map((item) => (
          <NumberLink
            key={paginationIdGenerator.next().value}
            href={getLinkPage(item)}
            $isDisabled={item === '...'}
          >
            {item}
          </NumberLink>
        ))}
      </NavBlock>
      <NavLink href={getPage(+page + 1)} $isDisabled={!next_page}>
        →
      </NavLink>
    </Container>
  );
};

export default Pagination;
