import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import styled, { css } from 'styled-components';
import { Container } from '../pages';

interface FullPageVideoInterface {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    allPrismicFullPageVideo: {
      edges: {
        node: {
          data: {
            page_title: {
              text: string;
            };
            meta_description?: string;
            meta_title: string;
            meta_image?: {
              url?: string;
            };
            video: {
              url?: string;
            };
          };
        };
      }[];
    };
  };
}

const StyledVideoContainer = styled.div<{ $headerHeight: number | undefined }>`
  ${({ $headerHeight = 0, theme }) => css`
    padding-top: ${(3 / 2) * ($headerHeight ?? 0)}px;
    padding-bottom: ${($headerHeight ?? 0) / 2}px;
    width: 100vw;
    max-width: ${theme.wrappers.xxl};
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const StyledVideo = styled.video`
  margin: 0;
  max-height: 100%;
  max-width: 100%;
`;

const FullPageVideoTemplate = ({ data }: FullPageVideoInterface): JSX.Element | null => {
  const [headerHeight, setHeaderHeight] = useState<number | undefined>(80);
  if (!data) return null;

  const {
    prismicGeneralConfig: {
      data: { favicon },
    },
    allPrismicFullPageVideo: { edges },
  } = data;
  const { video } = edges[0].node.data;

  useEffect(() => {
    const header = document.getElementById('coactive-header');
    setHeaderHeight(header?.offsetHeight);
  }, []);

  return (
    <Layout>
      <SEO
        meta={[{ name: 'robots', content: 'noindex' }]}
        meta_image={edges[0].node?.data?.meta_image?.url}
        meta_description={edges[0].node.data.meta_description}
        meta_title={edges[0].node.data.meta_title}
        title={edges[0].node.data.page_title?.text || 'Home'}
        faviconHref={favicon?.url || ''}
      />
      <Container>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <StyledVideoContainer $headerHeight={headerHeight}>
          <StyledVideo src={video.url} controls />
        </StyledVideoContainer>
      </Container>
    </Layout>
  );
};

export default withPrismicPreview(FullPageVideoTemplate);

export const query = graphql`
  query AllFullPageVideosQuery($uid: String) {
    prismicGeneralConfig {
      ...GeneralConfigFragment
    }
    allPrismicFullPageVideo(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          ...FullPageVideoFragment
        }
      }
    }
  }
`;
