import styled, { css } from 'styled-components';
import Text from '@components/text';
import Image from '@components/image';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Eyebrow = styled(Text)`
  margin-bottom: 1.5rem;
`;

export const WrapContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 3.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3rem;
    row-gap: 3rem;
    flex-wrap: wrap;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      margin-top: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      column-gap: 2rem;
      margin-top: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      row-gap: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.xs}) {
      justify-content: center;
      row-gap: 2.5rem;
    }
  `}
`;

export const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

export const StyledImage = styled(Image)`
  filter: grayscale(1);
  max-height: 100%;
  max-width: 200px;
  height: unset;
  width: unset;
`;

export const StyledInfoContainer = styled.div`
  margin-top: 1rem;
`;

export const StyledPartnerName = styled(Text)`
  font-weight: 600;
  display: block;
  text-align: center;
`;
