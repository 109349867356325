import React from 'react';
import { TextComponentProps } from '@components/text/text-props.type';
import { Container, Background, StyledEyebrow, Triangle } from './eyebrow.styled';

const Eyebrow = ({ stringText, className }: TextComponentProps): JSX.Element => (
  <Container className={className}>
    <Background>
      <Triangle layout="before" />
      <Triangle layout="after" />
      <StyledEyebrow>{stringText}</StyledEyebrow>
    </Background>
  </Container>
);

export default Eyebrow;
